<template>
    <div class="container">
        <c-banner :type="'newBook'" :bannerHeight="'600px'" />
        <img src="@/assets/imgs/home/newBookHeadIco.png" alt="" class="bigHead">
        <div class="book">
            <div class="detail">
                <img :src="detail.cover" alt="">
                <div>
                    <h3>{{detail.name}}</h3>
                    <div>
                        <div>
                            <ul>
                                <li>图书作者：{{detail.author}}</li>
                                <li>版次：{{detail.edition}}</li>
                                <li>出版时间：{{detail.date}}</li>
                            </ul>
                        </div>
                        <div>
                            <ul>
                                <li>出版社：{{detail.press}}</li>
                                <li>页数：{{detail.pages}}</li>
                                <li>馆藏索书号：{{detail.code}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="summaryBox">
                <div>
                    <img src="@/assets/imgs/home/book_info_summary_ico.png" alt="">
                    <p>内容简介</p>
                </div>
                <p>{{detail.summary}}</p>
            </div>
        </div>
    </div>
</template>
<script>
import cBanner from "@/components/c-banner";
export default {
    components: {
        cBanner
    },
    data() {
        return {
            detail: {}
        }
    },
    computed: {
        query() {
            return this.$route.query
        }
    },
    created(){
        let that = this;
        that.getDetail();
    },
    methods: {
        getDetail() {
            let that = this;
            console.log(that.query)
            let data = {
                id: that.query.id
            }
            that.api.bookDetail(data).then((res)=>{
                that.detail = res.data.data;
            })
        },
    }
}
</script>
<style lang="scss" scoped>
    .container{
        width: 100%;
        background-color: #F7F7F7;
        padding-bottom: 120px;
        >.bigHead{
            width: 259px;
            height: 49px;
            display: block;
            margin: 0 auto 46px;
        }
        >.book{
            width: 1200px;
            margin: 0 auto;
            text-align: left;
            box-shadow: 0px 4px 15px 1px #BFD1D5;
            padding: 40px;
            >.detail{
                display: flex;
                align-items: center;
                margin-bottom: 40px;
                padding-bottom: 40px;
                border-bottom: 1px dashed #e1e1e1;
                >img{
                    display: block;
                    width: 150px;
                    height: 215px;
                    margin-right: 30px;
                }
                >div{
                    width: 0%;
                    flex: 1;
                    >h3{
                        font-size: 30px;
                        color: #222;
                        line-height: 40px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-bottom: 40px;
                    }
                    >div{
                        display: flex;
                        >div{
                            width: 0%;
                            flex: 1;
                            >ul{
                                >li{
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    font-size: 18px;
                                    color: #666;
                                    line-height: 24px;
                                    margin-bottom: 22px;
                                }
                            }
                        }
                    }
                }
            }
            >.summaryBox{
                overflow: hidden;
                >div{
                    display: flex;
                    align-items: center;
                    margin-bottom: 18px;
                    >img{
                        display: block;
                        width: 24px;
                        height: 24px;
                        margin-right: 12px;
                    }
                    >p{
                        font-weight: bold;
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
                >p{
                    font-size: 14px;
                    color: #333;
                    line-height: 26px;
                }
            }
        }
    }
</style>