<template>
  <div class="container">
    <c-banner :type="'search'" :bannerHeight="'600px'">
      <div class="searchBox" slot="searchBox">
        <div>
          <img src="@/assets/imgs/banner/input_sousuo_ico.png" alt="" />
          <input type="text" placeholder="请输入搜索内容" v-model="searchVal" />
          <button @click="searchClick">搜索</button>
        </div>
      </div>
    </c-banner>
    <div class="listBox">
      <div class="searchHead">
        <div>
          <div></div>
          <h3>搜索结果</h3>
        </div>
      </div>
      <ul class="tab">
        <li
          v-for="item of tab"
          :key="item.id"
          :class="actTab == item.id ? 'act' : ''"
          @click="selectClass(item)"
        >
          {{ item.name }}
        </li>
      </ul>
      <ul class="list" v-if="list.length > 0">
        <li v-for="(item, index) of list" :key="index" @click="goDetail(item)">
          <h2>{{ item.title }}</h2>
          <p>{{ item.summary }}</p>
          <div>
            <div>{{ item.section | getSection }}</div>
            <p>{{ item.date }}</p>
          </div>
        </li>
      </ul>
      <div class="noDate" v-else>
        <img src="@/assets/imgs/noData/noData.png" alt="" />
      </div>
      <pager
        :total-page="pages"
        :initPage="pageNum"
        @go-page="goPageNum"
        v-if="pages > 1"
      />
    </div>
  </div>
</template>
<script>
import cBanner from "@/components/c-banner";
import pager from "@/components/pager";
export default {
  components: {
    cBanner,
    pager,
  },
  data() {
    return {
      tab: [
        {
          id: 1,
          name: "全部",
        },
        {
          id: 2,
          name: "地方文献",
        },
        {
          id: 3,
          name: "新书推荐",
        },
        {
          id: 4,
          name: "岸图讲堂",
        },
        {
          id: 5,
          name: "岸图展厅",
        },
        {
          id: 6,
          name: "政务公开",
        },
      ],
      actTab: 1,
      searchVal: "",
      pages: 1,
      pageNum: 1,
      list: [],
    };
  },
  computed: {
    params() {
      return this.$route.params;
    },
    query() {
      return this.$route.query;
    },
  },
  created() {
    let that = this;
    that.searchVal = that.query.val;
    that.getList();
  },
  mounted() {
    let that = this;
  },
  methods: {
    getList() {
      let that = this;
      switch (that.actTab) {
        case 1:
          that.allSearch();
          break;
        case 2:
          that.getliteratureSearch();
          break;
        case 3:
          that.getbookSearch();
          break;
        case 4:
          that.getlectureSearch();
          break;
        case 5:
          that.getexhibitionSearch();
          break;
        case 6:
          that.getbulletinSearch();
          break;

        default:
          that.allSearch();
          break;
      }
    },
    allSearch() {
      let that = this;
      let data = {
        page: that.pageNum,
        size: 2,
        keyword: that.trim(that.searchVal),
      };
      that.api.allSearch(data).then((res) => {
        let list = [];
        for (let i = 0; i < res.length; i++) {
          let resList = res[i].data.data.list;
          // if(resList.length > 1) {
          for (let j = 0; j < resList.length; j++) {
            resList[j].section = i + 2;
          }
          // }
          list = list.concat(resList);
        }
        that.list = list;
      });
    },
    getbulletinSearch() {
      let that = this;
      let data = {
        page: that.pageNum,
        size: 4,
        keyword: that.trim(that.searchVal),
      };
      that.api.bulletinSearch(data).then((res) => {
        if (that.pageNum == 1) {
          that.pages = res.data.data.pages;
        }
        let resList = res.data.data.list;
        // if(resList.length > 0) {
        for (let j = 0; j < resList.length; j++) {
          resList[j].section = 6;
        }
        // }
        that.list = resList;
      });
    },
    getexhibitionSearch() {
      let that = this;
      let data = {
        page: that.pageNum,
        size: 4,
        keyword: that.trim(that.searchVal),
      };
      that.api.exhibitionSearch(data).then((res) => {
        if (that.pageNum == 1) {
          that.pages = res.data.data.pages;
        }
        let resList = res.data.data.list;
        // if(resList.length > 1) {
        for (let j = 0; j < resList.length; j++) {
          resList[j].section = 5;
        }
        // }
        that.list = res.data.data.list;
      });
    },
    getliteratureSearch() {
      let that = this;
      let data = {
        page: that.pageNum,
        size: 4,
        keyword: that.trim(that.searchVal),
      };
      that.api.literatureSearch(data).then((res) => {
        if (that.pageNum == 1) {
          that.pages = res.data.data.pages;
        }
        let resList = res.data.data.list;
        // if(resList.length > 1) {
        for (let j = 0; j < resList.length; j++) {
          resList[j].section = 2;
          // }
        }
        that.list = res.data.data.list;
      });
    },
    getlectureSearch() {
      let that = this;
      let data = {
        page: that.pageNum,
        size: 4,
        keyword: that.trim(that.searchVal),
      };
      that.api.lectureSearch(data).then((res) => {
        if (that.pageNum == 1) {
          that.pages = res.data.data.pages;
        }
        let resList = res.data.data.list;
        // if(resList.length > 1) {
        for (let j = 0; j < resList.length; j++) {
          resList[j].section = 4;
        }
        // }
        that.list = res.data.data.list;
      });
    },
    getbookSearch() {
      let that = this;
      let data = {
        page: that.pageNum,
        size: 4,
        keyword: that.trim(that.searchVal),
      };
      that.api.bookSearch(data).then((res) => {
        if (that.pageNum == 1) {
          that.pages = res.data.data.pages;
        }
        let resList = res.data.data.list;
        // if(resList.length > 1) {
        for (let j = 0; j < resList.length; j++) {
          resList[j].section = 3;
        }
        // }
        that.list = res.data.data.list;
      });
    },
    goPageNum(obj) {
      let that = this;
      that.pageNum = obj.page;
      that.getList();
    },
    selectClass(item) {
      let that = this;

      let staticData = {
        click: true,
        pv: false,
        search: true,
      };
      that.addStatic(staticData);

      that.actTab = item.id;
      that.pageNum = 1;
      that.pages = 1;
      that.getList();
    },
    searchClick() {
      let that = this;
      if (!that.trim(that.searchVal)) return false;

      let staticData = {
        click: true,
        pv: false,
        search: true,
      };
      that.addStatic(staticData);

      that.pageNum = 1;
      that.getList();
    },
    goDetail(item) {
      let that = this;

      let staticData = {
        click: true,
        pv: false,
        search: true,
      };
      console.log(item, "bbbbbbbbbb");
      that.addStatic(staticData);
      if (item.section == 2) {
        // 地方文献-详情
        this.$store.commit("changeNavTitle", this.getSectionFn(item.section));
        this.$router.push({
          path: "/literatureOrAffairsInfo",
          query: {
            type: "affairs",
            id: item.id,
            name: item.title,
            search: true,
          },
        });
      } else if (item.section == 3) {
        // 书籍-详情
        that.$router.push({
          name: "newBookInfo",
          query: {
            id: item.id,
          },
        });
      } else if (item.section == 4) {
        // 讲堂-详情
        that.$router.push({
          name: "lectureInfo",
          query: {
            id: item.id,
          },
        });
      } else if (item.section == 5) {
        // 展厅-详情
        that.$router.push({
          name: "exhibitionInfo",
          query: {
            id: item.id,
          },
        });
      } else if (item.section == 6) {
        // 政务公开-详情
        if (item.type == 1) {
          window.open(item.content);
          let data = {
            id: item.id,
          };
          this.api.bulletinDetail(data);
        } else {
          this.$store.commit("changeNavTitle", this.getSectionFn(item.section));
          this.$router.push({
            path: "/literatureOrAffairsInfo",
            query: {
              type: "affairs",
              id: item.id,
              name: item.title,
              search: true,
            },
          });
        }
      }
    },
    // 去除前后空格
    trim(str) {
      //exhibitionPage
      return str.replace(/(^\s*)|(\s*$)/g, "");
    },
    addStatic(data) {
      this.api.statisticsSave(data).then((res) => {});
    },
    getSectionFn(val) {
      if (!val) return false;
      let section = "";
      switch (val) {
        case 2:
          section = "地方文献";
          break;
        case 3:
          section = "新书推荐";
          break;
        case 4:
          section = "岸图讲堂";
          break;
        case 5:
          section = "岸图展厅";
          break;
        case 6:
          section = "政务公开";
          break;

        default:
          section = "地方文献";
          break;
      }
      return section;
    },
  },
  watch: {
    params(newV) {
      let that = this;
    },
    query(newV) {
      let that = this;
      that.searchVal = newV.val;
      that.pageNum = 1;
      that.getList();
    },
  },
  filters: {
    getSection(val) {
      if (!val) return false;
      let section = "";
      switch (val) {
        case 2:
          section = "地方文献";
          break;
        case 3:
          section = "新书推荐";
          break;
        case 4:
          section = "岸图讲堂";
          break;
        case 5:
          section = "岸图展厅";
          break;
        case 6:
          section = "政务公开";
          break;

        default:
          section = "地方文献";
          break;
      }
      return section;
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  padding-bottom: 120px;
  overflow: hidden;
  > .listBox {
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    text-align: left;
    > .searchHead {
      margin-bottom: 40px;
      > div {
        display: inline-block;
        position: relative;
        > h3 {
          font-size: 18px;
          color: #333;
          line-height: 18px;
          position: relative;
        }
        > div {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 4px;
          background: #7fdff2;
        }
      }
    }
    > .tab {
      width: 100%;
      padding: 0 20px 20px;
      background: #f9fafa;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 28px;
      > li {
        color: #333;
        font-size: 16px;
        line-height: 38px;
        padding: 0 18px;
        cursor: pointer;
        margin-top: 20px;
        margin-right: 10px;
        &.act,
        &:hover {
          color: #fff;
          background: #7bdff2;
          border-radius: 8px;
        }
      }
    }
    > .list {
      overflow: hidden;
      > li {
        cursor: pointer;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #e1e1e1;
        > h2 {
          font-size: 18px;
          color: #333;
          line-height: 36px;
        }
        > p {
          font-size: 14px;
          color: #333;
          line-height: 22px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 20px;
        }
        > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          > div {
            border-radius: 26px;
            padding: 0 10px;
            line-height: 26px;
            font-size: 12px;
            color: #999;
            border: 1px solid #e1e1e1;
          }
          > p {
            font-size: 14px;
            color: #999;
          }
        }
      }
    }
    > .noDate {
      padding: 50px 0;
      > img {
        display: block;
        width: 221px;
        height: 226px;
        margin: 0 auto;
      }
    }
  }
}
</style>