<template>
    <div class="container">
        <div class="main">
            <div class="left">
                <ul>
                    <li v-for="item of classList" :key="item.id" @click="goDetail(item)" :class="[routeParams.type == item.type ? 'act' : '']">
                        <p>{{item.name}}</p>
                    </li>
                </ul>
            </div>
            <div class="right">
                <englishReaderServiceInfo />
            </div>
        </div>
    </div>
</template>

<script>
import englishReaderServiceInfo from '@/containers/englishReaderService/englishReaderServiceInfo'
export default {
    components: {
        englishReaderServiceInfo
    },
    data() {
        return {
            classList: [
                {
                    id: 1,
                    name: '读者须知',
                    type: 'noticeToReaders'
                },
                {
                    id: 2,
                    name: '办证须知',
                    type: 'notesForCertificateApplication'
                },
                {
                    id: 3,
                    name: 'Wi-Fi接入',
                    type: 'wifi'
                },
                {
                    id: 4,
                    name: '24小时自助图书馆',
                    type: 'putQuestions'
                },
                {
                    id: 5,
                    name: '服务数据',
                    type: 'serviceData'
                },
                {
                    id: 6,
                    name: '捐赠鸣谢',
                    type: 'donationThanks'
                },
                {
                    id: 7,
                    name: '图书分馆',
                    type: 'branchLibrary'
                },
            ]
        }
    },
    computed: {
        routeParams() {
            return this.$route.params
        }
    },
    mounted() {
        let that = this;
        
    },
    methods: {
        goDetail(item) {
            let that = this;
            that.$router.push({
                name: 'englishReaderService',
                params: {
                    type: item.type
                }
            })
        }
    }
};
</script>
<style lang="scss" scoped>
    .container{
        overflow: hidden;
        width: 100%;
        background: #F7F7F7;
        padding-bottom: 120px;
        >.main{
            width: 1200px;
            margin: 0 auto;
            display: flex;
            >.left{
                position: relative;
                width: 200px;
                margin-right: 20px;
                overflow: hidden;
                >ul{
                    position: relative;
                    left: 0;
                    top: 0;
                    width: 100%;
                    background: #fff;
                    text-align: left;
                    >li{
                        cursor: pointer;
                        padding: 0 20px;
                        height: 60px;
                        border-left: 4px solid #fff;
                        >p{
                            width: 100%;
                            border-bottom: 1px solid #E5E5E5;
                            height: 60px;
                            font-size: 16px;
                            color: #333;
                            line-height: 59px;
                        }
                        &:hover,
                        &.act{
                            border-left: 4px solid #7BDFF2;
                            background: #E7F3F5;
                            >p{
                                color: #7BDFF2;
                            }
                        }
                        &:last-child{
                            >p{
                                border-bottom: 0;
                            }
                        }
                    }
                }
            }
            >.right{
                width: 0%;
                flex: 1;
                background: #fff;
            }
        }
    }
</style>