import axios from "axios";
import store from "../store/index";

let isJSON = (str) => {
  if (typeof str == "string") {
    try {
      var obj = JSON.parse(str);
      // console.log('转换成功：'+obj);
      return true;
    } catch (e) {
      // console.log('error：'+str+'!!!'+e);
      return false;
    }
  }
  // console.log('It is not a string!')
};
// 路由请求拦截
// http request 拦截器
axios.interceptors.request.use(
  (config) => {
    // if (isJSON(localStorage.getItem("4_token"))) {
    //   let tokenObj = localStorage.getItem("4_token")
    //     ? JSON.parse(localStorage.getItem("4_token"))
    //     : {};
    //   for (const key in tokenObj) {
    //     if (store.state.dept + "token" == key) {
    //       config.headers = { "YT-Token": tokenObj[key] };
    //     }
    //   }
    // } else {
    //   localStorage.removeItem("4_token");
    // }
    return config;
  },
  (error) => {
    return Promise.reject(error.response);
  }
);

// 路由响应拦截
// http response 拦截器
axios.interceptors.response.use(
  (response) => {
    // console.log(response)
    // if (response.data.code == 1003) {
    //   store.commit("setIsUserInvaild", true);
    // }
    return response;
  },
  (error) => {
    return Promise.reject(error.message); // 返回接口返回的错误信息
  }
);
