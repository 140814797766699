<template>
  <div
    class="banner"
    :style="{ backgroundImage: 'url(' + bannerUrl + ')', height: bannerHeight }"
    ref="banner"
  >
    <!-- <div class="banner" ref="banner" :style="{ height: bannerHeight}" >
        <img :src="bannerUrl" alt="" :style="{height:bannerHeight}"> -->
    <slot name="searchBox"></slot>
    <slot name="sideNav"> </slot>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: ["type", "bannerHeight"],
  computed: {
    bannerUrl() {
      let that = this;
      let url = "";
      // console.log(that.type)
      switch (that.type) {
        case "home": //首页
          url = require("@/assets/shou.jpg");
          break;
        case "newBook": //新书推荐
          url = require("@/assets/imgs/banner/newBook.png");
          break;
        case "lecture": //讲堂
          url = require("@/assets/imgs/banner/lecture.png");
          break;
        case "exhibition": //展厅
          url = require("@/assets/imgs/banner/exhibition.png");
          break;
        case "about": //关于岸图
          url = require("@/assets/imgs/banner/about.png");
          break;
        case "readerService": //读者服务
          url = require("@/assets/imgs/banner/readerService.png");
          break;
        case "putQuestionsMethod": //咨询方式
          url = require("@/assets/imgs/banner/putQuestionsMethod.png");
          break;
        case "questions": //咨询问题
          url = require("@/assets/imgs/banner/questions.png");
          break;
        case "literature": //地方文献
          url = require("@/assets/imgs/banner/literature.png");
          break;
        case "affairs": //政务公开
          url = require("@/assets/aa.png");
          break;
        case "search": //搜索
          url = require("@/assets/imgs/banner/search.png");
          break;

        default:
          url = require("@/assets/0.jpg");
          break;
      }
      // console.log(url)
      return url;
    },
  },
  mounted() {
    let that = this;
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.banner {
  width: 100%;
  min-width: 1200px;
  height: 600px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1920px 100%;
  position: relative;
  margin-bottom: 46px;
  > .searchBox {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    > ul {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      > li {
        background-color: rgba($color: #000000, $alpha: 0.5);
        border-radius: 6px;
        line-height: 46px;
        padding: 0 16px;
        font-size: 18px;
        color: #fff;
        margin-right: 20px;
        cursor: pointer;
        &.act {
          background-color: rgba($color: #7bdff2, $alpha: 0.8);
        }
      }
    }
    > div {
      border-radius: 10px;
      border: 1px solid #fff;
      background-color: rgba($color: #fff, $alpha: 0.8);
      width: 900px;
      height: 74px;
      display: flex;
      align-items: center;
      overflow: hidden;
      position: relative;
      > img {
        position: absolute;
        left: 40px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 20px;
        height: 20px;
      }
      > input {
        display: block;
        width: 0%;
        flex: 1;
        outline: none;
        border: 0;
        height: 72px;
        line-height: 72px;
        padding: 0 70px;
        font-size: 16px;
      }
      > button {
        outline: none;
        color: #fff;
        line-height: 72px;
        width: 160px;
        background-color: #7bdff2;
        font-size: 18px;
        border: 0;
        cursor: pointer;
      }
    }
  }
  > .sideNav {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    > li {
      background-color: rgba($color: #000000, $alpha: 0.6);
      width: 80px;
      height: 70px;
      margin-bottom: 6px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      > img {
        display: block;
        width: 24px;
        height: 24px;
        margin-bottom: 10px;
      }
      > p {
        font-size: 14px;
        color: #fff;
      }
    }
  }
}
</style>