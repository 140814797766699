<template>
    <div class="head">
        <div>
            <img src="@/assets/imgs/home/moreHead_titleIco.png" alt="">
            <p>{{name}}</p>
        </div>
        <div @click="goPage">
            <p>更多</p>
            <img src="@/assets/imgs/home/moreHead_more.png" alt="">
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    props: ['name','link'],
    mounted() {
        // console.log(this)
    },
    methods: {
        goPage() {
            let that = this;

            let staticData = {
                click: true,
                pv: false,
                search: false
            }
            that.addStatic(staticData);

            let data = {
                name: that.link
            }
            if( that.link == 'literatureOrAffairs' ) {
                this.$store.commit('changeNavTitle' , '政务公开')
                data.params = {
                    type: 'affairs'
                }
                data.query = {
                    id: 1,
                    type:'affairs'
                }
            }
            that.$router.push(data)
        },
        addStatic(data) {
            this.api.statisticsSave(data).then((res)=>{})
        }
    }
}
</script>
<style lang="scss" scoped>
    .head{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 1200px;
        margin: 0 auto 30px;
        >div{
            display: flex;
            align-items: center;
            &:first-child{
                >img{
                    width: 18px;
                    height: 18px;
                    margin-right: 10px;
                }
                >p{
                    font-size: 20px;
                    font-weight: bold;
                    line-height: 26px;
                    color: #333;
                }
            }
            &:last-child{
                cursor: pointer;
                >img{
                    width: 11px;
                    height: 12px;
                }
                >p{
                    font-size: 14px;
                    color: #999;
                    line-height: 20px;
                    margin-right: 10px;
                }
            }
        }
    }
</style>