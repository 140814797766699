<template>
    <div class="container" v-if="list.length> 0">
        <moreHead :name="'岸图讲堂'" :link="'lectureList'" />
        <ul>
            <li v-for="item of list" :key="item.id" @click="goDetail(item)">
                <img :src="item.cover" alt="">
                <div>
                    <p>{{item.title}}</p>
                    <p>{{item.summary}}</p>
                    <div></div>
                </div>
                <div>
                    <p>{{item.date}}</p>
                    <div>
                        <img src="@/assets/imgs/home/lecture_playico.png" alt="">
                        <p>立即播放</p>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
import moreHead from '@/components/more_head'
export default {
    components: {
        moreHead
    },
    data() {
        return {
            list: []
        }
    },
    created() {
        let that = this;
        that.getList();
    },
    methods:{
        getList() {
            let that = this;
            let data = {
                page: 1,
                size: 4
            }
            that.api.lecturePage(data).then((res)=>{
                that.list = res.data.data.list;
            })
        },
        goDetail(item){
            let that = this;

            let staticData = {
                click: true,
                pv: false,
                search: false
            }
            that.addStatic(staticData);

            that.$router.push({
                name: 'lectureInfo',
                query: {
                    id: item.id
                }
            })
        },
        addStatic(data) {
            this.api.statisticsSave(data).then((res)=>{})
        }
    }
}
</script>
<style lang="scss" scoped>
    .container{
        width: 1200px;
        margin: 0 auto 50px;
        >ul{
            >li{
                // position: relative;
                cursor: pointer;
                width: 279px;
                float: left;
                box-shadow: 0px 0px 20px 6px rgba(175, 175, 175, 0.18);
                border-radius: 10px;
                text-align: left;
                margin-right: 28px;
                &:last-child{
                    margin-right: 0;
                }
                >img{
                    display: block;
                    width: 100%;
                    height: 180px;
                    margin-bottom: 14px;
                }
                >div:first-of-type{
                    padding: 0 14px;
                    >p:first-of-type{
                        color: #333;
                        font-size: 16px;
                        line-height: 22px;
                        margin-bottom: 10px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    >p:last-of-type{
                        color: #666;
                        font-size: 14px;
                        line-height: 22px;
                        height: 66px;
                        margin-bottom: 28px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }
                    >div{
                        border-bottom: 1px dashed #e5e5e5;
                        height: 0;
                        width: 100%;
                    }
                }
                >div:last-of-type{
                    height: 56px;
                    padding: 0 14px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    >p{
                        width: 0%;
                        flex: 1;
                        font-size: 12px;
                        color: #999;
                        margin-right: 30px;
                    }
                    >div{
                        display: flex;
                        align-items: center;
                        >img{
                            display: block;
                            width: 20px;
                            height: 20px;
                            margin-right: 10px;
                        }
                        >p{
                            color: #7BDFF2;
                            font-size: 14px;
                        }
                    }
                }
                
            }
            &::after{
                content: "";
                width: 0;
                height: 0;
                display: block;
                clear: both; 
                visibility: hidden; 
            }
        }
    }
</style>