import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

// api
import api from "./axios/index";
Vue.prototype.api = api;
// 设置标题
import setTitleHack from "./assets/js/setTitle";
Vue.prototype.setTitleHack = setTitleHack;
// swiper
import "swiper/dist/css/swiper.css";
import VueAwesomeSwiper from "vue-awesome-swiper";
Vue.use(VueAwesomeSwiper);


if(process.env.NODE_ENV == 'development'){

}else {
    console.log = () => {
        return ''
    }
}



new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
