import axios from "axios";

import "./interceptors";
// import store from "../store/index";
// import router from '../router'

// axios.defaults.baseURL = "/api";

let ip1 = 'https://www.huaxianlibrary.com/cms/api';
// if(){}

// let ip1 = 'http://192.168.2.20:8082/api'   //本地
// let ip1 = 'http://192.168.2.20:8082/api';
// let ip1 = '/api'

axios.defaults.withCredentials = true;
// axios.defaults.baseURL = process.env.NODE_ENV == 'development' ?
function setData(obj, type) {
  let ary = [];
  if (type == "path") {
    for (let p in obj) {
      ary.push(encodeURIComponent(obj[p]));
    }
    return ary.join("/");
  } else {
    for (let p in obj) {
      ary.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
    return "?" + ary.join("&");
  }
}
// let data = {
//     version: 108
// }
// console.log(setData(data,'path'))
/**
 * literatureTypes   地方文献分类
 * literaturePage  地方文献分类列表
 * literatureDetail  地方文献详情
 * bulletinTypes   政务公开分类
 * bulletinPage  政务公开分类列表
 * bulletinDetail  政务公开详情
 * bulletinCarousels  新闻公告轮播图  默认ID=1
 * bookPage  书籍列表
 * bookDetail  书籍详情
 * lectureColumns  讲堂类目
 * lecturePage  讲堂列表
 * lectureNewest 讲堂最新
 * lectureDetail  讲堂详情
 * lecturePageSpeaker  讲堂详情-同作者作品
 * exhibitionPage  展厅列表
 * exhibitionDetail  展厅详情
 * exhibitionImages  展厅详情-轮播图
 * questionTypes  问题分类
 * questionPage   问题列表
 * questionSave  提交问题
 * linkList  友情链接
 * statisticsSave 增加统计
 * statisticsStatistics  获取统计
 * bookSearch  书籍搜索
 * bulletinSearch  公告搜索
 * exhibitionSearch  展厅搜索
 * literatureSearch 文献搜索
 * lectureSearch  讲堂搜索
 * allSearch 全部搜索
 */
const api = {
  // , {params: data}
  literatureTypes: (data) => {
    return axios.get(ip1 + "/literature/types/");
  },

  literaturePage: (data) => {
    return axios.get(ip1 + "/literature/page/", {
      params: data,
    });
  },

  literatureDetail: (data) => {
    return axios.get(ip1 + "/literature/detail/", {
      params: data,
    });
  },

  bulletinTypes: (data) => {
    return axios.get(ip1 + "/bulletin/types/");
  },

  bulletinCarousels: (data) => {
    return axios.get(ip1 + "/bulletin/carousels/");
  },

  bulletinPage: (data) => {
    return axios.get(ip1 + "/bulletin/page/", {
      params: data,
    });
  },

  bulletinDetail: (data) => {
    return axios.get(ip1 + "/bulletin/detail/", {
      params: data,
    });
  },

  bookPage: (data) => {
    return axios.get(ip1 + "/book/page/", {
      params: data,
    });
  },

  bookDetail: (data) => {
    return axios.get(ip1 + "/book/detail/", {
      params: data,
    });
  },

  lecturePage: (data) => {
    return axios.get(ip1 + "/lecture/page/", {
      params: data,
    });
  },

  lectureNewest: (data) => {
    return axios.get(ip1 + "/lecture/newest/", {
      params: data,
    });
  },

  lectureColumns: (data) => {
    return axios.get(ip1 + "/lecture/columns/", {
      params: data,
    });
  },

  lectureDetail: (data) => {
    return axios.get(ip1 + "/lecture/detail/", {
      params: data,
    });
  },

  lecturePageSpeaker: (data) => {
    return axios.get(ip1 + "/lecture/page/speaker/", {
      params: data,
    });
  },
  //首页轮播图
  exhibitionCarousels: (data) => {
    return axios.get(ip1 + "/exhibition/carousels/", {
      params: data,
    });
  },
  exhibitionPage: (data) => {
    return axios.get(ip1 + "/exhibition/page/", {
      params: data,
    });
  },

  exhibitionDetail: (data) => {
    return axios.get(ip1 + "/exhibition/detail/", {
      params: data,
    });
  },

  exhibitionImages: (data) => {
    return axios.get(ip1 + "/exhibition/detail/images/", {
      params: data,
    });
  },

  questionTypes: (data) => {
    return axios.get(ip1 + "/question/types/", {
      params: data,
    });
  },
  //问题列表
  questionPage: (data) => {
    return axios.get(ip1 + "/question/common/page", {
      params: data,
    });
  },
  //提交
  questionSave: (data) => {

    return axios.post(ip1 + "/question/save/" + setData(data),);
    // return axios({
    //   method: 'post',
    //   url: ip1 + "/question/save",
    //   data: data,
    //   headers: {
    //     'Content-Type': 'application/x-www-form-urlencoded'
    //   }
    // });
  },
  //留言列表
  messageList: (data) => {
    return axios.get(ip1 + '/question/message/page', {
      params: data,
    });

  },
  linkList: (data) => {
    return axios.get(ip1 + "/link/list/", {
      params: data,
    });
  },

  statisticsSave: (data) => {
    return axios.post(ip1 + "/statistics/save/" + setData(data));
  },

  statisticsStatistics: (data) => {
    return axios.get(ip1 + "/statistics/statistics/", {
      params: data,
    });
  },

  bookSearch: (data) => {
    return axios.get(ip1 + "/book/search/", {
      params: data,
    });
  },

  bulletinSearch: (data) => {
    return axios.get(ip1 + "/bulletin/search/", {
      params: data,
    });
  },

  exhibitionSearch: (data) => {
    return axios.get(ip1 + "/exhibition/search/", {
      params: data,
    });
  },

  literatureSearch: (data) => {
    return axios.get(ip1 + "/literature/search/", {
      params: data,
    });
  },

  lectureSearch: (data) => {
    return axios.get(ip1 + "/lecture/search/", {
      params: data,
    });
  },

  allSearch: (data) => {
    return axios.all([api.literatureSearch(data), api.bookSearch(data), api.lectureSearch(data), api.exhibitionSearch(data), api.bulletinSearch(data)]);
  },
  serviceList: (data) => {
    return axios.get(ip1 + '/service/pc/list', {
      params: data,
    })
  },
  serveceInfo: (data) => {
    return axios.get(ip1 + '/service/detail', {
      params: data,
    })
  },
  digitalList: (data) => {
    return axios.get(ip1 + '/digital/pc/list', {
      params: data,
    })
  }
};

export default api;
