<template>
    <div class="container">
        <moreHead :name="'新闻公告'" :link="'literatureOrAffairs'" />
        <div>
            <swiper ref="homeSwiper" v-if="swiperList && swiperList.length > 0" :options="swiperOptions" class="newsSwiper">
                <!-- <swiper-slide v-for="(item, index) of swiperList" :key="index" :data-id="item.id" :data-type="item.type"  class="newsSwiperSlide"> -->
                <swiper-slide v-for="(item, index) of swiperList" :key="index" class="newsSwiperSlide">
                    <img :src="item.cover" alt="" @click="goDetail(item.id, item.type, item.content)">
                    <div>{{item.title}}</div>
                </swiper-slide>
                <div class="swiper-pagination newsSwiperPagination" slot="pagination"></div>
            </swiper>
            <ul>
                <template v-for="(item, index) of newsList">
                    <li :key="item.id" @click="goDetail(item.id, item.type, item.content)">
                        <div v-if="index == 0" class="first">
                            <h4>{{item.title}}</h4>
                            <p>{{item.summary}}</p>
                            <div>
                                <div>查看详情</div>
                                <p>{{item.date}}</p>
                            </div>
                        </div>
                        <div v-else class="other">
                            <p>{{item.title}}</p>
                            <p>{{item.date}}</p>
                        </div>
                    </li>
                </template>
            </ul>
        </div>
    </div>
</template>
<script>
let vm = null;
import moreHead from '@/components/more_head'
export default {
    components: {
        moreHead
    },
    data() {
        return {
            swiperList: [],
            swiperOptions: {
                loop: true,
                observer:true,//修改swiper自己或子元素时，自动初始化swiper
                observeParents:true,//修改swiper的父元素时，自动初始化swipe
                autoplay: {
                    delay: 2000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false,
                },
                pagination: {
                    el: '.swiper-pagination'
                },
                // on: {
                //     click: function(swiper) {
                //         var id = this.clickedSlide.getAttribute('data-id');
                //         var type = this.clickedSlide.getAttribute('data-type');
                //         console.log(id,a,'yyyyyyyyyyyyyyyy');
                        // console.log(item,'ggggggggdddddddddddd');
                        // vm.goDetail(id,type,content)
                //     }
                // },
            },
            newsList: [],
            newsDetail:''
        }
    },
    created(){
        let that = this;
        vm = this;
        that.getCarousels();
        that.getBulletinPage();
    },
    methods: {
        getCarousels() {
            let that = this;
            let data = {
                typeId: 1
            }
            that.api.bulletinCarousels(data).then((res)=>{
                let list = res.data.data;
                if(list.length < 4 && list.length > 0) {
                    let len = 4 - list.length;
                    for(let i=0;i<len;i++) {
                        list = list.concat(list);
                        // console.log(list)
                        if(list.length > 4) {
                            list = list.slice(0,4)
                            break;
                        }
                    }
                    that.swiperList = list;
                }else{
                    that.swiperList = list;
                }
            })
        },
        getBulletinPage() {
            let that = this;
            let data = {
                page: 1,
                size: 5,
                typeId: 1
            }
            that.api.bulletinTypes({
                page: 1,
                size: 5,
            }).then((res)=>{
                that.newsDetail = res.data.data[0];
                // console.log(that.newsDetail);
            })
            that.api.bulletinPage(data).then((res)=>{
                // console.log(res);
                that.newsList = res.data.data.list;
            })
        },
        goDetail(id, type, content){
            let that = this;
            let staticData = {
                click: true,
                pv: false,
                search: false
            }
            that.addStatic(staticData);

            // let routeUrl = that.$router.resolve({
            //     name: 'literatureOrAffairsInfo',
            //     params: {
            //         type: 'affairs'
            //     },
            //     query: {
            //         id: id
            //     }
            // })
            // window.open(routeUrl .href, '_blank');
            this.$store.commit('changeNavTitle' , null)
            if(type == 1) {
                window.open(content);
                let data = {
                    id: id
                }
                this.api.bulletinDetail(data);
            } else {
                that.$router.push({
                    path: '/literatureOrAffairsInfo',
                    params: {
                        // type: 'affairs'
                    },
                    query: {
                        type:'affairs',
                        id: id,
                        name:this.newsDetail.title
                    }
                })
            }
        },
        addStatic(data) {
            this.api.statisticsSave(data).then((res)=>{})
        }
    }
}
</script>
<style lang="scss" scoped>
    .container{
        width: 1200px;
        margin: 0 auto 50px;
        >div{
            display: flex;
            align-items: center;
            >.newsSwiper{
                width: 500px;
                height: 330px;
                margin-right: 40px;
                border-radius: 10px;
                .newsSwiperSlide{
                    cursor: pointer;
                    >img{
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                    >div{
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
                        height: 50px;
                        text-align: left;
                        line-height: 50px;
                        font-size: 14px;
                        color: #fff;
                        padding: 0 100px 0 20px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
            }
            >ul{
                width: 0%;
                flex: 1;
                >li{
                    text-align: left;
                    >div{
                        margin-bottom: 20px;
                        cursor: pointer;
                    }
                    >.first{
                        border-bottom: 1px dashed #e5e5e5;
                        padding-bottom: 20px;
                        >h4{
                            font-size: 16px;
                            color: #333;
                            margin-bottom: 12px;
                        }
                        >p{
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            text-overflow: ellipsis;
                            font-size: 14px;
                            color: #666;
                            line-height: 26px;
                            height: 78px;
                            margin-bottom: 12px;
                        }
                        >div{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            >div{
                                background-color: #7BDFF2;
                                border-radius: 4px;
                                color: #fff;
                                line-height: 28px;
                                width: 92px;
                                font-size: 14px;
                                text-align: center;
                            }
                            >p{
                                font-size: 14px;
                                color: #999;
                            }
                        }
                    }
                    >.other{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 14px;
                        color: #999;
                        >p:first-child{
                            width: 0%;
                            flex: 1;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
    }
</style>
<style lang="scss">
    .newsSwiperPagination{
        text-align: right !important;
    }
</style>