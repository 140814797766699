<template>
    <div class="putQuestions">
        <main v-if="isLeaveMessage">
            <div class="head">
                <div>
                    <h3>请留言</h3>
                    <div @click="getData">返回</div>
                </div>
            </div>
            <ul >
                <li v-for="(item, index) of submitData" :key="index" class="forms">
                    <div>{{item.name}}</div>
                    <div class="content">
                        <input type="text" v-model="item.val" v-if="item.type == 'input' || item.type == 'yzm'" @blur="rules(item)">
                        <textarea v-model="item.val" v-else-if="item.type == 'textarea'" @blur="rules(item)"></textarea>
                        <div v-else>
                            <input type="text" readonly v-model="item.val" @click="item.isShowAll = true">
                            <!-- <ul :class="item.isShowAll ? 'act' : ''">
                                <li @click="selectClass(item, classitem)" v-for="classitem of classList" :key="classitem.id">{{classitem.title}}</li>
                            </ul> -->
                        </div>
                    </div>
                    <div class="isMust" v-if="item.must && item.type != 'yzm'">&#42;必填</div>
                    <div v-if="item.type == 'yzm'" class="yzm">
                        <img :src="yzmSrc" alt="" @click="getYzm">
                    </div>
                </li>
                <li class="err">
                    <div></div>
                    <div>{{err}}</div> 
                </li>
                <li class="btn">
                    <div></div>
                    <div @click="submitBtnClick">提交</div> 
                </li>
            </ul>
            <div class="dialog" v-show="isShowDialog">提交成功</div>
        </main>
        
        <leaveMessage v-else  @myEvent="getData"/>


    </div>
</template>
<script>
    import leaveMessage from './leaveMessage'
    export default {
        data() {
            return {
                submitData: [
                    {
                        name: '姓名：',
                        must: true,
                        val: '',
                        type: 'input',
                        interFaceName: 'questioner'
                    },
                    {
                        name: '邮箱：',
                        must: false,
                        val: '',
                        type: 'input',
                        interFaceName: 'email'
                    },
                    {
                        name: '电话：',
                        must: false,
                        val: '',
                        type: 'input',
                        interFaceName: 'tel'
                    },
                    {
                        name: '咨询内容：',
                        must: true,
                        val: '',
                        type: 'textarea',
                        interFaceName: 'content'
                    },
                    {
                        name: '验证码：',
                        must: true,
                        val: '',
                        type: 'yzm',
                        interFaceName: 'code'
                    },
                ],
                resetData: [],
                err: '',
                // classList: [],
                // yzmSrc: 'http://192.168.2.20:8082/getVerifyCodeImage?time=' + new Date().getTime(),
                yzmSrc: 'http://www.jaqlib.com/cms/getVerifyCodeImage?time=' + new Date().getTime(),
                isShowDialog: false,
                isLeaveMessage : false
            }
        },
        components:{
            leaveMessage ,
        },
        computed: {
            
        },
        created() {
            let that = this;
            that.getClassList();
        },
        mounted() {
            let that = this;
            
        },
        methods: {
            getClassList() {
                // console.log(this.classList);
                let that = this;
                // that.api.questionTypes().then((res)=>{
                    // console.log(res.data);
                    // that.classList = res.data.data;
                    for (const i of that.submitData) {
                        if( i.name == '所属分类：' ) {
                            i.val = that.classList[0].title;
                            i.selected = that.classList[0].id;
                            break;
                        }
                    }
                    that.resetData = JSON.parse(JSON.stringify(that.submitData));
                // })
            },
            selectClass(parentItem, childItem){
                let that = this;
                parentItem.isShowAll = false;
                parentItem.val = childItem.title;
                parentItem.selected = childItem.id;
            },
            rules(item) {
                let that = this;
                let flag = true;
                that.err = '';
                if(item) {
                    if( !that.trim(item.val) && item.must ) {
                        flag = false;
                        that.err = item.name + '不能为空';
                    }
                }else{
                    for (const i of that.submitData) {
                        if( !that.trim(i.val) && i.must ) {
                            flag = false;
                            that.err = i.name + '不能为空';
                            break;
                        }
                    }
                }
                return flag;
            },
            submitBtnClick(){
                let that = this;

                let staticData = {
                    click: true,
                    pv: false,
                    search: false
                }
                that.addStatic(staticData);

                let mustFlag = that.rules();
                if( mustFlag ) {
                    that.putData();
                }else{
                    return false;
                }
            },
            putData(){
                let that = this;
                let data = {}
                for (const i of that.submitData) {
                    if( i.name == '所属分类：' ) {
                        data[i.interFaceName] = i.selected
                    }else{
                        data[i.interFaceName] = that.trim(i.val)
                    }
                }
                console.log(that.submitData);
                console.log(data);
                // data.typeId = 1
                that.api.questionSave(data).then((res)=>{

                    if(!res.data.flag){
                        that.err = res.data.msg;
                    }else{
                        that.isShowDialog = true;
                        setTimeout(() => {
                            that.isShowDialog = false;
                        }, 2000);
                        that.submitData = JSON.parse(JSON.stringify(that.resetData));
                    }
                })
            },
            getYzm(){
                let that = this;
                // that.yzmSrc = 'http://192.168.2.20:8082/getVerifyCodeImage?time=' + new Date().getTime();
                that.yzmSrc = 'http://www.jaqlib.com/cms/getVerifyCodeImage?time=' + new Date().getTime() 
            },
            // 去除前后空格
            trim(str){ 
                return str.replace(/(^\s*)|(\s*$)/g, ""); 
            },
            addStatic(data) {
                this.api.statisticsSave(data).then((res)=>{})
            },
            getData(){
                this.isLeaveMessage = !this.isLeaveMessage
            }
        }
    };
</script>
<style lang="scss" scoped>
    .putQuestions{
        width: 100%;
        background: #fff;
        padding: 40px;
        text-align: left;
        position: relative;
        >main{
            >.head{
                border-bottom: 1px solid #E1E1E1;
                padding-bottom: 20px;
                margin-bottom: 20px;
                >div{
                    // display: inline-block;
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    >h3{
                        font-size: 18px;
                        color: #333;
                        line-height: 18px;
                        position: relative;
                        z-index: 3;
                    }
                    >h3::after{
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        height: 4px;
                        background: #7FDFF2;
                        z-index: -1;
                    }
                    >div{
                        font-size: 14px;
                        padding: 4px  12px;
                        background: #0187ce;
                        border-radius: 6px;
                        color: #fff;
                        cursor: pointer;
                        // position: absolute;
                        // left: 0;
                        // bottom: 0;
                        // width: 100%;
                        // height: 4px;
                        // background: #7FDFF2;
                    }
                }
            }
            >ul{
                >li{
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 20px;
                    >div:first-child{
                        width: 80px;
                        font-size: 14px;
                        color: #444;
                        line-height: 40px;
                    }
                    &.forms{
                        >.content{
                            margin-right: 30px;
                            width: 420px;
                            input,
                            textarea{
                                display: block;
                                width: 100%;
                                height: 40px;
                                line-height: 40px;
                                border-radius: 3px;
                                background: #F8F8F8;
                                outline: none;
                                border: 0;
                                padding: 0 10px;
                                font-size: 14px;
                                color: #333;
                            }
                            textarea{
                                height: 130px;
                                resize: none;
                                margin: 0;
                            }
                            >div{
                                position: relative;
                                >ul{
                                    background: #fff;
                                    position: absolute;
                                    left: 0;
                                    top: 40px;
                                    width: 100%;
                                    height: 0;
                                    transition: all .5s;
                                    overflow: hidden;
                                    >li{
                                        font-size: 14px;
                                        color: #333;
                                        line-height: 40px;
                                        border-bottom: 1px solid #e1e1e1;
                                        padding: 0 10px;
                                        cursor: pointer;
                                        &:last-child{
                                            border-bottom: 0;
                                        }
                                    }
                                    &.act{
                                        border: 1px solid #e1e1e1;
                                        height: auto;
                                    }
                                }
                            }
                        }
                        >.isMust{
                            color: #D12121;
                            font-size: 14px;
                            line-height: 40px;
                        }
                        >.yzm{
                            width: 70px;
                            height: 40px;
                            >img{
                                display: block;
                                width: 70px;
                                height: 40px;
                            }
                        }
                    }
                    &.err{
                        >div:last-child{
                            color: #D12121;
                            font-size: 14px;
                        }
                    }
                    &.btn{
                        >div:last-child{
                            width: 160px;
                            height: 44px;
                            line-height: 44px;
                            background: #7BDFF2;
                            border-radius: 3px;
                            text-align: center;
                            color: #fff;
                            font-size: 14px;
                            cursor: pointer;
                        }
                    }
                }
            }
            >.dialog{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                background-color: rgba($color: #000000, $alpha: .8);
                padding: 20px;
                color: #fff;
                font-size: 18px;
                border-radius: 5px;
            }
        }
        
    }
</style>