<template>
  <div class="headContainer">
    <div class="headSearch" :class="isShowHeadSSearchBox ? 'act' : ''">
      <div>
        <div>
          <img src="@/assets/imgs/c_head/headSearchBox_sousuo.png" alt="" />
          <input type="text" placeholder="请输入搜索内容" v-model="searchVal" />
          <button @click="goSearchPage">搜索</button>
        </div>
        <img
          src="@/assets/imgs/c_head/headSearchBox_closeIco.png"
          alt=""
          @click="isShowHeadSSearchBox = false"
        />
      </div>
    </div>
    <div class="head">
      <div>
        <img src="@/assets/huaxian.png" alt="" class="logo" />
        <nav>
          <ul>
            <template v-for="(item, index) of list">
              <li
                :key="index"
                :class="actId == item.id ? 'act' : ''"
                @click="goPage(item, index)"
                @mouseenter="showDigitalRead(item)"
                @mouseleave="hideDigitalRead(item)"
              >
                <p>{{ item.name }}</p>
                <div class="actMask"></div>
                <div class="digitalRead" v-if="item.isShowDigitalRead">
                  <div>
                    <div class="digitalReadItem">
                      <h4>数字资源</h4>
                      <ul>
                        <li
                          v-for="item of digitalList.first"
                          :key="item.id"
                          @click="goOutLink(item.url)"
                        >
                          · {{ item.title }}
                        </li>
                      </ul>
                    </div>
                    <div>
                      <div
                        class="digitalReadItem"
                        v-for="item of digitalList.second"
                        :key="item.id"
                      >
                        <h4>{{ item.title }}</h4>
                        <div>
                          <img :src="item.cover" alt="" />
                          <div @click="goOutLink(item.url)"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </template>
          </ul>
        </nav>
        <img
          src="@/assets/imgs/c_head/sousuo.png"
          alt=""
          class="searchIco"
          @click="isShowHeadSSearchBox = true"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        {
          id: 1,
          name: "首页",
          pathName: "/",
        },
        {
          id: 2,
          name: "关于滑图",
          pathName: "about",
        },
        {
          id: 3,
          name: "读者服务",
          pathName: "readerService",
          params: {
            type: "noticeToReaders",
          },
        },
        {
          id: 4,
          name: "参考咨询",
          pathName: "consult",
          children: ["problemList", "putQuestions"],
        },
        {
          id: 5,
          name: "数字阅读",
          pathName: "",
          isShowDigitalRead: false,
        },
        {
          id: 6,
          name: "地方文献",
          pathName: "literatureOrAffairs",
          params: {
            type: "literature",
          },
          children: ["literatureOrAffairsInfo"],
        },
        {
          id: 7,
          name: "政务公开",
          pathName: "literatureOrAffairs",
          params: {
            type: "affairs",
          },
          children: ["literatureOrAffairsInfo"],
        },
        // {
        //     id: 8,
        //     name: 'English',
        //     pathName: 'englishReaderService',
        //     params: {
        //         type: 'noticeToReaders'
        //     },
        // }
      ],
      actId: 1,
      isShowHeadSSearchBox: false,
      searchVal: "",
      digitalList: {
        first: [],
        second: [],
      },
    };
  },
  computed: {
    routeInfo() {
      return this.$route;
    },
  },
  created() {
    let that = this;
    that.getActId();
    this.digitalListFn();
  },
  methods: {
    goPage(item, index) {
      if (index === 6) {
        window.open("http://www.hnhx.gov.cn/portal/index.htm");
        return;
      }
      if (item.id != 5) {
        this.actId = item.id;
      }
      // console.log(this.actId , item.id);
      let that = this;
      if (!item.pathName) return false;

      let staticData = {
        click: true,
        pv: false,
        search: false,
      };
      that.addStatic(staticData);

      // let data = {
      //     name: item.pathName
      // };
      // if( item.params ) {
      //     // console.log(item);
      //     // item.params.name = item.name

      //     data.params = item.params
      //     // data.name = item.name
      // }
      this.$store.commit("changeNavTitle", item.name);
      // that.$router.push(data);
      // console.log(item.pathName);

      // console.log(that.actId);
      if (item.pathName == "/") {
        this.$router.push({
          path: "/",
        });
      } else {
        this.$router.push({
          path: "/" + item.pathName,
          query: {
            type: item.params ? item.params.type : "",
            id: item.id,
          },
        });
      }
    },
    showDigitalRead(item) {
      let that = this;
      if (!item.pathName) {
        item.isShowDigitalRead = true;
      }
    },
    hideDigitalRead(item) {
      let that = this;
      if (!item.pathName) {
        item.isShowDigitalRead = false;
      }
    },
    goOutLink(link) {
      let that = this;
      let staticData = {
        click: true,
        pv: false,
        search: false,
      };
      that.addStatic(staticData);
      window.open(link);
    },
    goSearchPage() {
      let that = this;
      if (!that.trim(that.searchVal)) return false;
      that.isShowHeadSSearchBox = false;
      let staticData = {
        click: true,
        pv: false,
        search: true,
      };
      that.addStatic(staticData);
      that.$router.push({
        name: "search",
        query: {
          val: that.searchVal,
        },
      });
      that.searchVal = "";
    },
    getActId() {
      let that = this;
      // console.log(that.routeInfo)
      // console.log(that.list);
      for (let a of that.list) {
        // console.log(a)
        if (a.pathName == that.routeInfo.name) {
          if (a.params) {
            if (a.params.type == that.routeInfo.params.type) {
              that.actId = a.id;
              break;
            } else {
              that.actId = a.id;
            }
          } else {
            that.actId = a.id;
          }
        } else if (a.children) {
          if (a.children.indexOf(that.routeInfo.name) != -1) {
            if (a.params) {
              if (a.params.type == that.routeInfo.params.type) {
                that.actId = a.id;
                break;
              } else {
                that.actId = a.id;
              }
            } else {
              that.actId = a.id;
            }
          }
        }
      }
    },
    addStatic(data) {
      this.api.statisticsSave(data).then((res) => {});
    },
    // 去除前后空格
    trim(str) {
      return str.replace(/(^\s*)|(\s*$)/g, "");
    },
    digitalListFn() {
      // console.log('digitalList');
      this.api.digitalList().then((res) => {
        console.log(res.data.data);
        this.digitalListFlter(res.data.data);
      });
    },
    digitalListFlter(arr) {
      arr.forEach((el, index) => {
        if (el.cover == "") {
          // console.log(index);
          this.digitalList.first.push(el);
        } else {
          this.digitalList.second.push(el);
        }
      });
      console.log(this.digitalList);
    },
  },
  watch: {
    routeInfo: {
      handler(newValue, oldValue) {
        let that = this;
        // console.log(newValue)
        // that.getActId();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.headContainer {
  width: 100%;
  position: fixed;
  z-index: 99;
  > .headSearch {
    position: relative;
    left: 0;
    top: 0;
    overflow: hidden;
    width: 100%;
    height: 0;
    background-image: url("~@/assets/imgs/c_head/headSearchBox_bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1920px 212px;
    transition: all 0.2s;
    > div {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div {
        width: 1065px;
        height: 66px;
        border-radius: 10px;
        overflow: hidden;

        position: relative;
        display: flex;
        > img {
          position: absolute;
          left: 30px;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 20px;
          height: 20px;
        }
        > input {
          display: block;
          width: 0%;
          flex: 1;
          outline: none;
          border: 0;
          height: 66px;
          line-height: 66px;
          padding: 0 70px;
          font-size: 16px;
          background: rgba(0, 0, 0, 0.56);
          color: #fff;
        }
        > button {
          outline: none;
          color: #fff;
          line-height: 66px;
          width: 138px;
          background-color: #7bdff2;
          font-size: 18px;
          border: 0;
          cursor: pointer;
        }
      }
      > img {
        display: block;
        width: 22px;
        height: 22px;
        cursor: pointer;
      }
    }
    &.act {
      height: 212px;
    }
  }
  > .head {
    width: 100%;
    height: 80px;
    background-color: rgba(0, 0, 0, 0.7);
    // background-color: #000;
    position: relative;
    left: 0;
    top: 0;
    > div {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      color: #fff;
      > .logo {
        width: 140px;
        height: 75px;
        display: block;
        // margin-right: 160px;
      }
      > nav {
        width: 0%;
        flex: 1;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        > ul {
          width: 100%;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          > li {
            position: relative;
            cursor: pointer;
            > p {
              width: 100px;
              font-size: 16px;
              height: 80px;
              line-height: 80px;
            }
            > .actMask {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 0;
              background-color: rgba(123, 223, 242, 0.64);
              transition: all 0.1s;
            }
            > .digitalRead {
              position: fixed;
              left: 0;
              top: 80px;
              width: 100%;
              // height: 284px;
              padding: 40px 0;
              background-image: url("~@/assets/imgs/banner/digitalRead.png");
              background-repeat: no-repeat;
              background-position: center;
              background-size: 1920px 100%;
              > div {
                width: 1200px;
                // overflow-x: scroll;
                margin: 0 auto;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                flex-wrap: wrap;
                // .digitalReadItem{
                > div:first-child {
                  // width: 2%;
                  // height: auto;
                  // background: gray;
                }
                > div:last-child {
                  width: 60%;
                  // background: red;
                  display: flex;
                  justify-content: space-between;
                  flex-wrap: wrap;
                  > .digitalReadItem:nth-last-child(3n) {
                    margin-top: 20px;
                  }
                }
                .digitalReadItem {
                  width: 294px;
                  // height: 194px;
                  min-height: 194px;
                  padding: 12px 27px;
                  text-align: left;
                  background-color: #fff;
                  > h4 {
                    border-bottom: 1px solid #e1e1e1;
                    font-size: 18px;
                    color: #222;
                    margin-bottom: 16px;
                    line-height: 59px;
                  }
                  > ul {
                    > li {
                      font-size: 14px;
                      color: #333;
                      margin-bottom: 12px;
                    }
                  }
                  > div {
                    position: relative;
                    width: 100%;
                    height: 98px;
                    border-radius: 3px;
                    > img {
                      display: block;
                      width: 100%;
                      height: 100%;
                    }
                    > div {
                      position: absolute;
                      left: 0;
                      top: 0;
                      width: 100%;
                      height: 100px;
                      background-image: url("~@/assets/imgs/c_head/digital_btnico.png");
                      background-position: center;
                      background-repeat: no-repeat;
                      background-size: 102px 40px;
                    }
                  }
                }
                // }
              }
            }
            &.act {
              > .actMask {
                height: 80px;
                border-top: 5px solid #7bdff2;
              }
            }
            &:hover {
              > .actMask {
                border-top: 5px solid #7bdff2;
                height: 80px;
              }
            }
          }
        }
      }
      > .searchIco {
        width: 20px;
        height: 20px;
        display: block;
        margin-left: 50px;
        cursor: pointer;
      }
    }
  }
}
</style>
