<template>
    <div class="container" v-if="list.length >= 0">
        <moreHead :name="'新书推荐'" :link="'newBookList'"/>
        <ul>
            <li v-for="item of list" :key="item.id" @click="goDetail(item)">
                <img :src="item.cover" alt="">
                <div>
                    <p>{{item.name}}</p>
                    <p>{{item.summary}}</p>
                    <p>著 | {{item.author}}</p>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
import moreHead from '@/components/more_head'
export default {
    components: {
        moreHead
    },
    data() {
        return {
            list: []
        }
    },
    created() {
        let that = this;
        that.getList();
    },
    methods: {
        getList() {
            let that = this;
            let data = {
                page: 1,
                size: 6
            }
            that.api.bookPage(data).then((res)=>{
                // console.log(res)
                that.list = res.data.data.list;
            })
        },
        goDetail(item){
            let that = this;

            let staticData = {
                click: true,
                pv: false,
                search: false
            }
            that.addStatic(staticData);

            that.$router.push({
                name: 'newBookInfo',
                query: {
                    id: item.id
                }
            })
        },
        addStatic(data) {
            this.api.statisticsSave(data).then((res)=>{})
        }
    }
}
</script>
<style lang="scss" scoped>
    .container{
        width: 1200px;
        margin: 0 auto 50px;
        >ul{
            overflow: hidden;
            >li{
                cursor: pointer;
                width: 580px;
                float: left;
                background: #F8F8F9;
                border-radius: 8px;
                overflow: hidden;
                margin-bottom: 30px;
                display: flex;
                text-align: left;
                >img{
                    display: block;
                    width: 150px;
                    height: 215px;
                }
                >div{
                    width: 0%;
                    flex: 1;
                    padding: 16px 30px;
                    >p:first-child{
                        color: #333;
                        font-size: 16px;
                        line-height: 22px;
                        margin-bottom: 10px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    >p:nth-child(2){
                        color: #666;
                        font-size: 14px;
                        line-height: 22px;
                        height: 66px;
                        margin-bottom: 28px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }
                    >p:last-child{
                        color: #999;
                        font-size: 14px;
                        line-height: 20px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
                &:nth-child(2n){
                    float: right;
                }
            }
        }
    }
</style>