<template>
    <div class="container">
        <c-banner :type="'lecture'" :bannerHeight="'600px'" />
        <div class="lecture">
            <img src="@/assets/imgs/home/lectureHeadIcoBlack.png" alt="" class="bigHead">
            <div class="latelyUpdate">
                <div>
                    <div>
                        <div></div>
                        <h3>最近更新</h3>
                    </div>
                </div>
                <ul>
                    <li v-for="item of newList" :key="item.id" @click="goDetail(item)">
                        <img :src="item.cover" alt="">
                        <div>
                            <h4>{{item.title}}</h4>
                            <div>
                                <div>
                                    <p>{{item.speaker}}</p>
                                    <p>{{item.date}}</p>
                                </div>
                                <img src="@/assets/imgs/home/lecture_playico.png" alt="">
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <ul class="tab">
                <li v-for="item of tab" :key="item.id" :class="actTab == item.id? 'act' : ''" @click="selectClass(item)">{{item.title}}</li>
            </ul>
            <ul class="list">
                <li v-for="item of list" :key="item.id" @click="goDetail(item)">
                    <div>
                        <img :src="item.cover" alt="">
                        <div></div>
                    </div>
                    <div>
                        <p>{{item.title}}</p>
                        <p>{{item.date}}</p>
                    </div>
                </li>
            </ul>
            <pager :total-page="pages" :initPage="pageNum" @go-page="goPageNum" v-if="pages>1" />
        </div>
    </div>
</template>
<script>
import pager from '@/components/pager'
import cBanner from "@/components/c-banner";
export default {
    components: {
        pager,
        cBanner
    },
    data() {
        return {
            newList: [],
            tab: [
                {
                    id: -1,
                    title:'全部',
                },
            ],
            actTab: -1,
            list: [],
            pageNum: 1,
            pages: 1,
        }
    },
    created() {
        let that = this;
        that.getNewList();
        that.getClassList();
        that.getList();
    },
    methods: {
        getNewList() {
            let that = this;
            that.api.lectureNewest().then((res)=>{
                that.newList = res.data.data;
            })
        },
        getClassList() {
            let that = this;
            that.api.lectureColumns().then((res)=>{
                let list = that.tab.concat(res.data.data);
                that.tab = list
            })
        },
        getList() {
            let that = this;
            let data = {
                page: that.pageNum,
                size: 8,
                columnId: that.actTab == -1 ? '' : that.actTab
            }
            that.api.lecturePage(data).then((res)=>{
                if(that.pageNum == 1) {
                    that.pages = res.data.data.pages
                }
                that.list = res.data.data.list;
            })
        },
        goPageNum(obj) {
            // console.log(obj.page)
            let that = this;
            that.pageNum = obj.page;
            that.getList();
        },
        selectClass(item) {
            let that = this;

            let staticData = {
                click: true,
                pv: false,
                search: true
            }
            that.addStatic(staticData);

            that.actTab = item.id;
            that.pageNum = 1;
            that.getList();
        },
        goDetail(item){
            let that = this;

            let staticData = {
                click: true,
                pv: false,
                search: false
            }
            that.addStatic(staticData);

            that.$router.push({
                name: 'lectureInfo',
                query: {
                    id: item.id
                }
            })
        },
        addStatic(data) {
            this.api.statisticsSave(data).then((res)=>{})
        }
    }
}
</script>
<style lang="scss" scoped>
    .container{
        width: 100%;
        padding-bottom: 120px;
        >.lecture{
            >.bigHead{
                width: 259px;
                height: 49px;
                display: block;
                margin: 0 auto 46px;
            }
            width: 1200px;
            margin: 0 auto;
            text-align: left;
            >.latelyUpdate{
                overflow: hidden;
                >div{
                    overflow: hidden;
                    margin-bottom: 28px;
                    >div{
                        display: inline-block;
                        position: relative;
                        >h3{
                            font-size: 24px;
                            color: #333;
                            line-height: 24px;
                            position: relative;
                        }
                        >div{
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            width: 100%;
                            height: 4px;
                            background: #7FDFF2;
                        }
                    }
                }
                >ul{
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    >li{
                        cursor: pointer;
                        width: 588px;
                        padding: 26px;
                        border-radius: 4px;
                        border: 1px solid #E1E1E1;
                        display: flex;
                        align-items: center;
                        >img{
                            display: block;
                            width: 208px;
                            height: 162px;
                            margin-right: 22px;
                        }
                        >div{
                            width: 0%;
                            flex: 1;
                            >h4{
                                font-size: 22px;
                                color: #222;
                                line-height: 30px;
                                margin-bottom: 30px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                            >div{
                                display: flex;
                                align-items: center;
                                >div{
                                    width: 0%;
                                    flex: 1;
                                    margin-right: 14px;
                                    >p{
                                        font-size: 16px;
                                        color: #666;
                                        margin-bottom: 16px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }
                                }
                                >img{
                                    display: block;
                                    width: 32px;
                                    height: 32px;
                                }
                            }
                        }
                    }
                }
            }
            >.tab{
                width: 100%;
                padding: 0 20px 20px;
                background: #F9FAFA;
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 28px;
                >li{
                    color: #333;
                    font-size: 16px;
                    line-height: 38px;
                    padding: 0 18px;
                    cursor: pointer;
                    margin-top: 20px;
                    margin-right: 10px;
                    &.act,
                    &:hover{
                        color: #fff;
                        background: #7BDFF2;
                        border-radius: 8px;
                    }
                }
            }
            >.list{
                >li{
                    float: left;
                    width: 279px;
                    margin-right: 28px;
                    margin-bottom: 20px;
                    border-radius: 4px;
                    border: 1px solid #E1E1E1;
                    overflow: hidden;
                    cursor: pointer;
                    >div:first-child{
                        width: 100%;
                        height: 148px;
                        position: relative;
                        >img{
                            display: block;
                            width: 100%;
                            height: 100%;
                        }
                        >div{
                            background-color: rgba($color: #000000, $alpha: .3);
                            background-image: url("~@/assets/imgs/home/lecture_playico_white.png");
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: 69px 69px;
                            position: absolute;
                            left: 0;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            display: none;
                        }
                    }
                    >div:last-child{
                        padding: 20px;
                        >p:first-child{
                            color: #222;
                            font-size: 18px;
                            line-height: 24px;
                            height: 48px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            margin-bottom: 10px;
                        }
                        >p:last-child{
                            font-size: 14px;
                            color: #999;
                        }
                    }
                    &:hover{
                        >div:first-child{
                            >div{
                                display: block;
                            }
                        }
                    }
                    &:nth-child(4n){
                        margin-right: 0;
                    }
                }
                &::after{
                    content: "";
                    width: 0;
                    height: 0;
                    display: block;
                    visibility: hidden;
                    clear: both;
                }
            }
        }
    }
</style>
