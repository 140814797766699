<template>
    <div class="putQuestionsMethod">
        <!-- <img :src="imgLink" alt=""> -->
        <div v-html="serveceInfoProps.content"></div>
    </div>
</template>

<script>

export default {
    props:['serveceInfo'],
    components: {
        
    },
    data() {
        return {
            // SonActType:'' ,
            serveceInfoProps:''
        }
    },
    computed: {
        // imgLink() {
        //     let that = this;
        //     let url = '';
        //     switch (this.SonActType) {
        //         case 'noticeToReaders':
        //             url = 'https://resource.yuntuys.com/static/jiangan/readerService/noticeToReaders.png';
        //             break;
        //         case 'notesForCertificateApplication':
        //             url = require("@/assets/0.jpg");
        //             break;
        //         case 'wifi':
        //             url = 'https://resource.yuntuys.com/static/jiangan/readerService/wifi.png';
        //             break;
        //         case 'putQuestions':
        //             url = require("@/assets/0.jpg");
        //             break;
        //         case 'serviceData':
        //             url = require("@/assets/0.jpg");
        //             break;
        //         case 'donationThanks':
        //             url = require("@/assets/0.jpg");
        //             break;
        //         case 'branchLibrary':
        //             url = require("@/assets/0.jpg");
        //             break;
        //         default: url = require("@/assets/0.jpg");
        //             break;
        //     }
        //     return url;
        // }
    },
    mounted() {
        this.serveceInfoProps = this.serviceInfo || ''
    },
    methods: {
        
    },
    watch:{
        // actType(newVal , old){
        //     // console.log(newVal);
        //     this.SonActType = newVal
        // },
        serveceInfo(val){
            this.serveceInfoProps = val
        }
    }
};
</script>
<style lang="scss" scoped>
    .putQuestionsMethod{
        width: 100%;
        background: #fff;
        padding: 40px;
        >img{
            display: block;
            width: 100%;
        }
    }
</style>