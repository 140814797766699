import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    pvs: 0,
    pv: 0,
    navTitle:null
  },
  mutations: {
    setPvs(state, val) {
      state.pvs = val;
    },
    setPv(state, val) {
      state.pv = val;
    },
    changeNavTitle(state, val) {
      state.navTitle = val;
    },
  },
  actions: {},
  modules: {},
});
