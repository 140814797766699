<template>
  <div id="app" ref="app">
    <!-- head -->
    <c-head ref="head" />
    <!-- :style="{minHeight: mainH +'px', paddingBottom: '120px'}" -->
    <div>
      <router-view />
    </div>
    <!-- foot -->
    <c-foot ref="foot" />
  </div>
</template>
<script>
import cHead from "@/components/c-head";

import cFoot from "@/components/c-foot";

export default {
  components: {
    cHead,
    cFoot,
  },
  data() {
    return {
      // mainH: 0,
      
    }
  },
  computed: {
    
  },
  mounted() {
    //解决状态管理刷新  数据消失
    localStorage.getItem("userMsg") && this.$store.replaceState(Object.assign(this.$store.state,JSON.parse(localStorage.getItem("userMsg"))));            
    //在页面刷新时将vuex里的信息保存到localStorage里      
    window.addEventListener("beforeunload",()=>{          
      localStorage.setItem("userMsg",JSON.stringify(this.$store.state))      
    })
    let that = this;
    // that.mainH = that.$refs.app.clientHeight - that.$refs.head.$el.clientHeight - that.$refs.foot.$el.clientHeight;
  },
  methods: {
    
  }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  // padding-top: 80px;
  width: 100%;
  // height: 100%;
  // overflow-y: auto;
  overflow: hidden;
}
</style>
