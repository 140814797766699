import { render, staticRenderFns } from "./lecture.vue?vue&type=template&id=c8864640&scoped=true&"
import script from "./lecture.vue?vue&type=script&lang=js&"
export * from "./lecture.vue?vue&type=script&lang=js&"
import style0 from "./lecture.vue?vue&type=style&index=0&id=c8864640&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8864640",
  null
  
)

export default component.exports