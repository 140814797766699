<template>
    <div class="container_lectureInfo">
        <c-banner :type="'lecture'" :bannerHeight="'600px'" />
        <div class="box">
            <img src="@/assets/imgs/home/lectureHeadIcoWhite.png" alt="" class="bigHead">
            <div>
                <div class="videoBox" v-if="detail.type!=0">
                    <video :src="detail.video" controls preload></video>
                </div>
                <div class="videoBox zhuozhong" v-else v-html="detail.video">
                    <!-- <iframe height=498 width=510 src="" frameborder=0 ></iframe> -->
                </div>
                <div class="info">
                    <div class="detail">
                        <h4>{{detail.title}}</h4>
                        <p>{{detail.speaker}}</p>
                        <p>{{detail.date}}</p>
                    </div>
                    <div class="list">
                        <p>{{detail.speaker}}相关作品</p>
                        <ul>
                            <li v-for="item of list" :key="item.id" @click="playItem(item)">
                                <img :src="item.cover" alt="">
                                <div>
                                    <h4>{{item.title}}</h4>
                                    <p>{{item.date}}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import cBanner from "@/components/c-banner";
export default {
    components: {
        cBanner
    },
    data() {
        return {
            detail: {},
            list: [],
            
        }
    },
    computed: {
        id() {
            return this.$route.query.id
        }
    },
    created() {
        let that = this;
        that.getDetail();
    },
    methods: {
        getDetail() {
            let that = this;
            let data = {
                id: that.id
            };
            that.api.lectureDetail(data).then((res)=>{
                that.detail = res.data.data;
                console.log(this.detail , 'detail');
            })
        },
        getSpeakList() {
            let that = this;
            let data = {
                page: 1,
                size: 9999,
                speaker: that.detail.speaker
            }
            that.api.lecturePageSpeaker(data).then((res)=>{
                that.list = res.data.data.list;
            })
        },
        playItem(item){
            console.log(item)
            let that = this;
            that.$router.push({
                name: 'lectureInfo',
                query: {
                    id: item.id
                }
            })
        }
    },
    watch: {
        id(newV) {
            let that = this;
            that.getDetail();
        },
        detail(newV) {
            let that = this;
            that.getSpeakList();
        }
    }
}
</script>
<style lang="scss" scoped>
    .container_lectureInfo{
        width: 100%;
        overflow: hidden;
        background-image: url("~@/assets/imgs/home/lectureInfoBg.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 1920px 100%;
        padding-bottom: 120px;
        >.box{
            width: 1200px;
            margin: 0 auto;
            text-align: left;
            overflow: hidden;
            >.bigHead{
                width: 259px;
                height: 49px;
                display: block;
                margin: 0 auto 46px;
            }
            >div{
                width: 100%;
                display: flex;
                border-radius: 6px;
                >.videoBox{
                    width: 840px;
                    height: 578px;
                    overflow: hidden;
                    background: #1E1E1E;
                    >video{
                        width: 100%;
                        height: 100%;
                    }
                    iframe{
                        width: 100% !important;
                        height: 100% !important;
                    }
                }
                
                >.info{
                    width: 0%;
                    flex: 1;
                    height: 578px;
                    display: flex;
                    flex-direction: column;
                    >.detail{
                        width: 100%;
                        height: 140px;
                        padding: 16px 24px;
                        background: #434343;
                        >h4{
                            font-size: 24px;
                            color: #fff;
                            line-height: 32px;
                            margin-bottom: 8px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        >p{
                            font-size: 18px;
                            color: #D7D7D7;
                            margin-bottom: 10px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                    >.list{
                        width: 100%;
                        height: 0%;
                        flex: 1;
                        background: #393939;
                        display: flex;
                        flex-direction: column;
                        overflow: hidden;
                        padding: 0 20px 20px;
                        >p{
                            line-height: 60px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size: 18px;
                            color: #fff;
                        }
                        >ul{
                            height: 0%;
                            flex: 1;
                            overflow-y: auto;
                            >li{
                                margin-bottom: 18px;
                                display: flex;
                                >img{
                                    display: block;
                                    width: 120px;
                                    height: 100px;
                                    margin-right: 20px;
                                }
                                >div{
                                    width: 0%;
                                    flex: 1;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: space-between;
                                    >h4{
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-box-orient: vertical;
                                        -webkit-line-clamp: 2;
                                        font-size: 18px;
                                        color: #fff;
                                    }
                                    >p{
                                        font-size: 16px;
                                        color: #C9C9C9;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>



<style lang="scss">
    .videoBox{
        iframe{
            width: 100% !important;
            height: 100% !important;
        }
    }
</style>