<template>
    <div class="container" v-if="list.length> 0">
        <moreHead :name="'岸图展厅'" :link="'exhibitionList'" />
        <swiper ref="homeSwiper" v-if="list && list.length > 0" :options="swiperOptions" class="exhibitionSwiper">
            <swiper-slide v-for="(item, index) of list" :key="index" :data-id="item.id" class="exhibitionSwiperSlide">
                <img :src="item.background" alt="">
                <div class="mask"></div>
                <div class="info">
                    <div>
                        <p>{{item.title}}</p>
                        <img src="@/assets/imgs/home/exhibition_goInfo.png" alt="">
                    </div>
                    <p>{{item.summary}}</p>
                </div>
            </swiper-slide>
            <div class="swiper-pagination exhibitionSwiperPagination" slot="pagination"></div>
        </swiper>
        
    </div>
</template>
<script>
let vm = null;
import moreHead from '@/components/more_head'
export default {
    components: {
        moreHead
    },
    data() {
        return {
            list: [],
            swiperOptions: {
                loop: true,
                observer:true,//修改swiper自己或子元素时，自动初始化swiper
                observeParents:true,//修改swiper的父元素时，自动初始化swipe
                spaceBetween: 60,
                autoplay: {
                    delay: 5000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false,
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable :true,
                },
                watchSlidesProgress: true,
                slidesPerView: 'auto',
                centeredSlides: true,
                on: {
                    click: function(swiper) {
                        var id = this.clickedSlide.getAttribute('data-id');
                        vm.goDetail(id);
                    }
                },
            },
        }
    },
    created() {
        vm = this;
        let that = this;
        that.getList();
    },
    methods: {
        getList() {
            let that = this;
            let data = {
                page: 1,
                size: 4,
            }
            that.api.exhibitionCarousels().then((res)=>{
                // console.log(res.data.data , 'exhibitionPage');
                let list = res.data.data;
                if(list.length < 4 && list.length > 0) {
                    let len = 4 - list.length;
                    for(let i=0;i<len;i++) {
                        list = list.concat(list);
                        // console.log(list)
                        if(list.length > 4) {
                            list = list.slice(0,4)
                            break;
                        }
                    }
                    that.list = list;
                }else{
                    that.list = list;
                }
                
            })
        },
        goDetail(id){
            let that = this;

            let staticData = {
                click: true,
                pv: false,
                search: false
            }
            that.addStatic(staticData);

            that.$router.push({
                name: 'exhibitionInfo',
                query: {
                    id: id
                }
            })
        },
        addStatic(data) {
            this.api.statisticsSave(data).then((res)=>{})
        }
    }
}
</script>
<style lang="scss" scoped>
    .container{
        // width: 100%;
        width: 1200px;
        min-width: 1200px;
        margin: 0 auto;
        >.exhibitionSwiper{
            width: 100%;
            padding-bottom: 100px;
            .exhibitionSwiperSlide{
                width: 600px;
                height: 300px;
                border-radius: 10px;
                // background: #fff;
                cursor: pointer;
                text-align: left;
                overflow: hidden;
                >img{
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: top;
                }
                >.mask{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba($color: #000000, $alpha: .5);
                    display: none;
                }
                >.info{
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    background: rgba($color: #fff, $alpha: .8);
                    width: 0;
                    overflow: hidden;
                    transition: all .5s;
                    >div{
                        display: flex;
                        align-items: center;
                        margin-bottom: 30px;
                        >p{
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            width: 0%;
                            flex: 1;
                            font-size: 16px;
                            color: #333;
                            line-height: 28px;
                            margin-right: 100px;
                        }
                        >img{
                            display: block;
                            width: 34px;
                            height: 34px;
                            object-fit: cover;
                        }
                    }
                    >p{
                        // overflow: hidden;
                        // text-overflow: ellipsis;
                        // display: -webkit-box;
                        // -webkit-line-clamp: 2;
                        // -webkit-box-orient: vertical;
                        font-size: 14px;
                        line-height: 24px;
                        color: #666;
                        width: 100%;
                        //没有高度啊  你加了有
                        // height: 65%;
                        text-overflow: ellipsis;
                        display: -webkit-box !important; 
                        -webkit-box-orient: vertical !important;
                        -webkit-line-clamp:4 !important;
                        overflow: hidden !important;
                    }
                    
                }
                &.swiper-slide-active{
                    >.mask{
                        display: block;
                        // background: red;
                    }
                    >.info{
                        height: 70%;
                        border-left: 10px solid #7BDFF2;
                        width: 300px;
                        padding: 30px;
                        >p{
                            // color: ;
                            // height: 65%;
                            // background: red;
                            // display: -webkit-box;
                            // -webkit-box-orient: vertical;
                            // -webkit-line-clamp: 4;
                            // overflow: hidden;
                        }
                    }
                }
            }
            .swiper-pagination{
                bottom: 20px;
                
            }
        }
    }
</style>
<style lang="scss">
    .exhibitionSwiperPagination{
        .swiper-pagination-bullet{
            width: 140px !important;
            height: 4px !important;
            border-radius: 4px !important;
            background: #7BDFF2 !important;
            margin-right: 30px !important;
        }
    }
    
</style>