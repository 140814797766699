<template>
    <div class="container">
        <c-banner :type="'readerService'" :bannerHeight="'600px'" />
        <div class="main">
            <p class="navTitle" style="text-algin:left">
                <img src="../assets/imgs/home/from.png" alt="">
                您当前的位置是 : 网站首页&nbsp;>&nbsp;{{this.$store.state.navTitle}}&nbsp;>&nbsp;
                    <span >
                        {{classList.length!=0?classList[actIndex].title:''}}
                    </span>
            </p>
            <div>
                <div class="left">
                    <ul>
                        <li v-for="(item , index) of classList" :key="item.id" @click="goDetail(item , index)" :class="[actIndex == index ? 'act' : '']">
                            <p>{{item.title}}</p>
                        </li>
                    </ul>
                </div>
                <div class="right">
                    <readerServiceInfo :serveceInfo="serveceInfo" :actType="actType"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import readerServiceInfo from '@/containers/readerService/readerServiceInfo'
import cBanner from "@/components/c-banner";
export default {
    components: {
        readerServiceInfo,
        cBanner
    },
    data() {
        return {
            classList: [
            ],
            navTitle:'',
            actIndex:0 ,
            actType:'noticeToReaders' ,
            serveceInfo : null
        }
    },
    computed: {
        routeParams() {
            return this.$route.query
        }
    },
    mounted() {
        let that = this;
        this.api.serviceList().then(res=>{
            this.classList = res.data.data
            console.log(this.classList , 'classList');
            that.getServeceInfo(res.data.data[0].id)
        })
    },
    methods: {
        goDetail(item , index) {
            // console.log(item);
            this.actIndex = index
            let that = this;
            //统计数据
            let staticData = {
                click: true,
                pv: false,
                search: true
            }
            that.addStatic(staticData);
            this.getServeceInfo(item.id)
        },
        addStatic(data) {
            this.api.statisticsSave(data).then((res)=>{})
        } ,
        getServeceInfo(val){
            this.api.serveceInfo({id:val}).then(res=>{
                // console.log(res.data.data);
                this.serveceInfo = res.data.data
            })
        },
    }
};
</script>
<style lang="scss" scoped>
    .container{
        overflow: hidden;
        width: 100%;
        background: #F7F7F7;
        padding-bottom: 120px;
        >.main{
            width: 1200px;
            margin: 0 auto;
            >p{
                text-align: left;
                font-size: 14px;
                color: #666666;
                margin-bottom: 25px;
                display: flex;
                align-items: center;
                >img{
                    width: 16px;
                    height: 17px;
                    margin-right: 10px;
                }
                >span:last-child{
                    font-weight: 600;
                    color: #222222;
                }
            }
            >div{
                display: flex;
                >.left{
                    position: relative;
                    width: 200px;
                    margin-right: 20px;
                    overflow: hidden;
                    >ul{
                        position: relative;
                        left: 0;
                        top: 0;
                        width: 100%;
                        background: #fff;
                        text-align: left;
                        >li{
                            cursor: pointer;
                            padding: 0 20px;
                            height: 60px;
                            border-left: 4px solid #fff;
                            >p{
                                width: 100%;
                                border-bottom: 1px solid #E5E5E5;
                                height: 60px;
                                font-size: 16px;
                                color: #333;
                                line-height: 59px;
                            }
                            &:hover,
                            &.act{
                                border-left: 4px solid #7BDFF2;
                                background: #E7F3F5;
                                >p{
                                    color: #7BDFF2;
                                }
                            }
                            &:last-child{
                                >p{
                                    border-bottom: 0;
                                }
                            }
                        }
                    }
                }
                >.right{
                    width: 0%;
                    flex: 1;
                    background: #fff;
                }
            }
        }
    }
</style>