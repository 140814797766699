<template>
  <div class="container">
    <c-banner :type="type" :bannerHeight="'600px'" />
    <div class="main">
      <p class="navTitle" style="text-algin: left">
        <img src="../assets/imgs/home/from.png" alt="" />
        您当前的位置是 : 网站首页&nbsp;>&nbsp;{{
          this.$store.state.navTitle
        }}&nbsp;>&nbsp;
        <span v-if="classList[actIndex]">
          {{ classList[actIndex].name }}
        </span>
      </p>
      <div>
        <div class="left">
          <ul>
            <li
              v-for="(item, index) of classList"
              :key="item.id"
              @click="goDetail(item, index)"
              :class="[actIndex == index ? 'act' : '']"
            >
              <p>{{ item.name }}</p>
            </li>
          </ul>
        </div>
        <div class="right">
          <!-- <router-view></router-view> -->
          <putQuestionsMethod v-if="actIndex == 0" />
          <problemList v-if="actIndex == 1" />
          <putQuestions v-if="actIndex == 2" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cBanner from "@/components/c-banner";
import putQuestionsMethod from "../containers/consult/putQuestionsMethod";
import putQuestions from "../containers/consult/putQuestions";
import problemList from "../containers/consult/problemList";
export default {
  components: {
    cBanner,
    putQuestions,
    putQuestionsMethod,
    problemList,
  },
  data() {
    return {
      classList: [
        {
          id: 1,
          name: "咨询方式",
          pathName: "putQuestionsMethod",
        },
        {
          id: 2,
          name: "常见问题",
          pathName: "problemList",
        },
        {
          id: 3,
          name: "用户留言",
          pathName: "putQuestions",
        },
      ],
      type: "putQuestionsMethod",
      actIndex: 0,
    };
  },
  computed: {
    pathName() {
      return this.$route.name;
    },
  },
  created() {
    let that = this;
    if (that.pathName == "putQuestionsMethod") {
      that.type = "putQuestionsMethod";
    } else {
      that.type = "questions";
    }
  },
  mounted() {
    let that = this;
  },
  methods: {
    goDetail(item, index) {
      let that = this;
      this.actIndex = index;
      let staticData = {
        click: true,
        pv: false,
        search: true,
      };
      that.addStatic(staticData);

      if (item.id == 1) {
        that.type = "putQuestionsMethod";
      } else {
        that.type = "questions";
      }
      // that.$router.push({
      //     // name: item.pathName
      //     path:'/consult/' +item.pathName  ,
      //     query:{
      //         id : this.$route.query.id
      //     }
      // })
    },
    addStatic(data) {
      this.api.statisticsSave(data).then((res) => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  overflow: hidden;
  width: 100%;
  background: #f7f7f7;
  padding-bottom: 120px;
  > .main {
    width: 1200px;
    margin: 0 auto;

    .navTitle {
      font-size: 14px;
      // font-family: MicrosoftYaHei;
      color: #666666;
      margin-bottom: 25px;
      display: flex;
      align-items: center;
      > img {
        width: 16px;
        height: 17px;
        margin-right: 10px;
      }
      > span:last-child {
        font-weight: 600;
        color: #222222;
      }
    }
    > div {
      display: flex;
      > .left {
        position: relative;
        width: 200px;
        margin-right: 20px;
        overflow: hidden;
        > ul {
          position: relative;
          left: 0;
          top: 0;
          width: 100%;
          background: #fff;
          text-align: left;
          > li {
            cursor: pointer;
            padding: 0 20px;
            height: 60px;
            border-left: 4px solid #fff;
            > p {
              width: 100%;
              border-bottom: 1px solid #e5e5e5;
              height: 60px;
              font-size: 16px;
              color: #333;
              line-height: 59px;
            }
            &:hover,
            &.act {
              border-left: 4px solid #7bdff2;
              background: #e7f3f5;
              > p {
                color: #7bdff2;
              }
            }
            &:last-child {
              > p {
                border-bottom: 0;
              }
            }
          }
        }
      }
      > .right {
        width: 0%;
        flex: 1;
        background: #fff;
      }
    }
  }
}
</style>