<template>
    <div class="container">
        <c-banner :type="'exhibition'"  :bannerHeight="'300px'" />
        <div class="exhibition">
            <img src="@/assets/imgs/home/exhibitionHeadIco.png" alt="" class="bigHead">
            <div class="detail">
                <h3>{{detail.title}}</h3>
                <p v-html="detail.content"></p>
            </div>
            <div class="imgs">
                <swiper ref="topSwiper" v-if="list && list.length > 0" :options="swiperTop" class="topSwiper">
                    <swiper-slide v-for="(item, index) of list" :key="index" class="topSwiperSlide">
                           
                        <img :src="item.cover" alt="">
                      <p class="aa">{{item.title}}</p>
                    </swiper-slide>
                    
                </swiper>

                <div class="swiper-button-next next"></div>
                <div class="swiper-button-prev prev"></div>
                <swiper ref="bottomSwiper" v-if="list && list.length > 0" :options="swiperBottom" class="bottomSwiper">
                    <swiper-slide v-for="(item, index) of list" :key="index" class="bottomSwiperSlide">
                        <img :src="item.cover" alt="">
                    </swiper-slide>
                </swiper>
            </div>
        </div>
        
    </div>
</template>
<script>
import cBanner from "@/components/c-banner";
export default {
    components: {
        cBanner
    },
    data() {
        return {
            detail: {},
            list: [],
            swiperTop: {
                // spaceBetween: 10,
                
                loop: true,
                loopedSlides: 3,
                loopAdditionalSlides: 3, 
                navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
                },
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false
                },
            },
            swiperBottom: {
                spaceBetween: 10,
                slidesPerView: 4,
                touchRatio: 0.2,
                loop: true,
                loopedSlides: 3,
                loopAdditionalSlides: 3, 
                slideToClickedSlide: true
            },
        }
    },
    computed: {
        id() {
            return this.$route.query.id
        }
    },
    created() {
        let that = this;
        that.getDetail();
        that.getImgList();
    },
    mounted() {
        let that = this;
        
    },
    methods: {
        getDetail() {
            let that = this;
            let data = {
                id: that.id
            }
            //页面文字
            that.api.exhibitionDetail(data).then((res)=>{
                that.detail = res.data.data;
                console.log(res.data.data,'detail');
            })
        },
        getImgList() {
            let that = this;
            let data = {
                exhibitionId: that.id
            }
            that.api.exhibitionImages(data).then((res)=>{
                // console.log(res.data.data,'123');
                let list = res.data.data;
                console.log(list,'这是数据，，，，，，，，，');
                if(list.length < 6 && list.length > 0) {
                    let len = 6 - list.length;
                    for(let i=0;i<len;i++) {
                        list = list.concat(list);
                        // console.log(list)
                        if(list.length > 6) {
                            list = list.slice(0,6)
                            break;
                        }
                    }    
                    that.list = list;
                    
                }else{
                    that.list = list;
                }
                that.$nextTick(()=>{
                    const topSwiper = that.$refs.topSwiper.swiper;
                    const bottomSwiper = that.$refs.bottomSwiper.swiper;
                    topSwiper.controller.control = bottomSwiper
                    bottomSwiper.controller.control = topSwiper
                })
            })
        },
    },
    watch: {
        id(newV) {
            let that = this;
            that.getDetail();
            that.getImgList();
        },
    }
}
</script>
<style lang="scss" scoped>
    .container{
        width: 100%;
        padding-bottom: 120px;
        >.exhibition{
            width: 1200px;
            margin: 0 auto;
            >.bigHead{
                width: 259px;
                height: 49px;
                display: block;
                margin: 0 auto 46px;
            }
            >.detail{
                margin-bottom: 30px;
                >h3{
                    font-size: 28px;
                    color: #333;
                    line-height: 36px;
                    margin-bottom: 20px;
                }
                >p{
                    font-size: 14px;
                    color: #333;
                    line-height: 28px;
                    text-align: left;
                }
            }
            >.imgs{
                position: relative;
                .topSwiper{
                    margin-bottom: 20px;
                    .topSwiperSlide{
                        border-radius: 4px;
                        // overflow: hidden;
                        height: 800px;
                        >img{
                            width: 100%;
                            height: 96%;
                            object-fit: contain ;
                        }
                    }
                }
                .bottomSwiper{
                    margin-top: 60px;
                    width: 100%;
                    height: 165px;
                    position: relative;
                    .bottomSwiperSlide{
                        
                        border-radius: 4px;
                        overflow: hidden;
                        
                        >img{
                            // display: block;
                            // margin: auto;
                            height: 100%;
                            // object-fit: cover ;
                        }
                        
                    }
                    .swiper-slide-active::before{
                            content: '';
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            background: rgba($color: #000000, $alpha: 0.2);
                            z-index: 9;

                    }
                }
                .next{
                    position: absolute;
                    right: 30px;
                    // top: 170px;
                    top: 40%;
                    transform: translateY(-50%);
                    outline: none;
                    margin: 0;
                    width: 60px;
                    height: 60px;
                    background-image: url("~@/assets/imgs/home/exhibition_next.png");
                    background-size: 100% 100%;
                }
                .prev{
                    position: absolute;
                    left: 30px;
                    // top: 170px;
                    outline: none;
                    top: 40%;
                    transform: translateY(-50%);
                    margin: 0;
                    width: 60px;
                    height: 60px;
                    background-image: url("~@/assets/imgs/home/exhibition_prev.png");
                    background-size: 100% 100%;
                }
            }
        }
    }
</style>
