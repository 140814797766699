<template>
    <div class="list">
        <div class="head">
            <div>
                <div></div>
                <h3>{{actName}}</h3>
            </div>
        </div>
        <ul>
            <li v-for="item of list" :key="item.id" @click="goDetail(item)">
                <div class="time">
                    <p>{{item.date | getDay}}</p>
                    <p>{{item.date | getMonAndYear}}</p>
                </div>
                <div class="info">
                    <img :src="item.cover" alt="" v-if="item.cover">
                    <div>
                        <h4>{{item.title}}</h4>
                        <p>{{item.summary}}</p>
                    </div>
                </div>
            </li>
        </ul>
        <pager :total-page="pages" :initPage="pageNum" @go-page="goPageNum" v-if="pages>1" />
    </div>
</template>

<script>
import pager from '@/components/pager'
import literatureOrAffairsList from '@/containers/literatureOrAffairs/literatureOrAffairsList'
export default {
    components: {
        literatureOrAffairsList,
        pager
    },
    data() {
        return {
            list: [],
            pageNum: 1,
            pages: 1,
            resourcesId:1
        }
    },
    props: ['actId', 'actName'],
    computed: {
        // params() {
        //     return this.$route.params
        // },
        query() {
            // console.log(this.$route.query);
            return this.$route.query
        }
    },
    created() {
        let that = this;
        this.resourcesId = this.actId
        // console.log(that.query.type);
        if(that.query.type == 'affairs') {
            that.getBulletinPage();
        }else if(that.query.type == 'literature') {
            that.getLiteraturePage();
        }
    },
    mounted() {
        let that = this;

    },
    methods: {
        getBulletinPage() {
            let that = this;
            let data = {
                page: that.pageNum,
                size: 4,
                typeId: that.resourcesId
            }
            that.api.bulletinPage(data).then((res)=>{
                that.list = res.data.data.list;
                that.pages = res.data.data.pages;
            })
        },
        getLiteraturePage() {
            let that = this;
            let data = {
                page: that.pageNum,
                size: 4,
                typeId: that.resourcesId
            }
            that.api.literaturePage(data).then((res)=>{
                that.list = res.data.data.list;
                that.pages = res.data.data.pages;
            })
        },
        goPageNum(obj) {
            // console.log(obj.page)
            let that = this;
            that.pageNum = obj.page;
            if(that.query.type == 'affairs') {
                that.getBulletinPage();
            }else if(that.query.type == 'literature') {
                that.getLiteraturePage();
            }
        },
        goDetail(item) {
            let that = this;
            let staticData = {
                click: true,
                pv: false,
                search: false
            }
            that.addStatic(staticData);
            // let routeUrl = this.$router.resolve({
            //     name: "literatureOrAffairsInfo",
            //     params: {
            //         type: that.params.type
            //     },
            //     query: {
            //         id: item.id
            //     }
            // });
            // window.open(routeUrl.href, '_blank');
            // this.$router.push({
            //     path: "/literatureOrAffairsInfo",
            //     query: {
            //         type: that.query.type,
            //         id: item.id,
            //         name:that.actName
            //     }
            // });

            if(item.type == 1) {
                window.open(item.content);
               let data = {
                    id: item.id
                }
                this.api.bulletinDetail(data);
            } else {
                that.$router.push({
                    path: '/literatureOrAffairsInfo',
                    params: {
                        // type: 'affairs'
                    },
                    query: {
                        type:this.query.type,
                        id: item.id,
                        name:that.actName
                    }
                })
            }
        },
        addStatic(data) {
            this.api.statisticsSave(data).then((res)=>{})
        }
    },
    watch: {
        params(newV) {
            let that = this;
            // that.type = newV.type;
            // that.pageNum = 1;
            // if(that.params.type == 'affairs') {
            //     that.getBulletinPage();
            // }else if(that.params.type == 'literature') {
            //     that.getLiteraturePage();
            // }
        },
        query(newV) {
            let that = this;
            that.pageNum = 1;

            this.resourcesId = this.actId
            // console.log(newV , 'new')
            that.pageNum = 1;
            if(that.query.type == 'affairs') {
                that.getBulletinPage();
            }else if(that.query.type == 'literature') {
                that.getLiteraturePage();
            }
        },
        actId(newV) {
            let that = this;
            that.pageNum = 1;
            this.resourcesId = newV
            if(that.query.type == 'affairs') {
                that.getBulletinPage();
            }else if(that.query.type == 'literature') {
                that.getLiteraturePage();
            }
            // this.resourcesId = newV
            // console.log(newV , 'new')
            // that.pageNum = 1;
            // if(that.query.type == 'affairs') {
            //     that.getBulletinPage();
            // }else if(that.query.type == 'literature') {
            //     that.getLiteraturePage();
            // }
        }
    },
    filters: {
        getMonAndYear(val) {
            if(!val) return false;
            return val.slice(0,-3)
        },
        getDay(val) {
            if(!val) return false;
            return val.slice(-2)
        }
    }
};
</script>
<style lang="scss" scoped>
    .list{
        width: 100%;
        padding: 30px 40px;
        text-align: left;
        >.head{
            border-bottom: 1px solid #E1E1E1;
            padding-bottom: 20px;
            margin-bottom: 20px;
            >div{
                display: inline-block;
                position: relative;
                >h3{
                    font-size: 18px;
                    color: #333;
                    line-height: 18px;
                    position: relative;
                }
                >div{
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 4px;
                    background: #7FDFF2;
                }
            }
        }
        >ul{
            overflow: hidden;
            >li{
                cursor: pointer;
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                >.time{
                    width: 70px;
                    margin-right: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    >p:first-child{
                        color: #34CAE7;
                        font-size: 34px;
                        // margin-bottom: 10px;
                    }
                    >p:last-child{
                        color: #B7B7B7;
                        font-size: 14px;
                    }
                }
                >.info{
                    width: 0%;
                    flex: 1;
                    display: flex;
                    align-items: center;
                    padding-bottom: 20px;
                    border-bottom: 1px solid #eee;
                    >img{
                        display: block;
                        width: 140px;
                        height: 86px;
                        margin-right: 20px;
                    }
                    >div{
                        width: 0%;
                        flex: 1;
                        >h4{
                            font-size: 18px;
                            color: #333;
                            margin-bottom: 10px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        >p{
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            font-size: 14px;
                            color: #333;
                            line-height: 22px;
                            // height: 44px;
                        }
                    }
                }
            }
        }
    }
</style>