<template>
    <div class="container">
        <c-banner :type="'exhibition'" :bannerHeight="'600px'" />
        <div class="exhibition">
            <img src="@/assets/imgs/home/exhibitionHeadIco.png" alt="" class="bigHead">
            <ul>
                <li v-for="item of list" :key="item.id" @click="goDetail(item)">
                    <img :src="item.cover" alt="">
                    <div>
                        <h4>{{item.title}}</h4>
                        <p>{{item.summary}}</p>
                        <p>{{item.date}}</p>
                    </div>
                </li>
            </ul>
            <pager :total-page="pages" :initPage="pageNum" @go-page="goPageNum" v-if="pages>1" />
        </div>
    </div>
</template>
<script>
import pager from '@/components/pager'
import cBanner from "@/components/c-banner";
export default {
    components: {
        pager,
        cBanner
    },
    data() {
        return {
            list: [],
            pageNum: 1,
            pages: 1,
        }
    },
    created(){
        console.log('xiangqing');
        let that = this;
        that.getList();
    },
    methods: {
        getList() {
            let that = this;
            let data = {
                page: that.pageNum,
                size: 3
            }
            that.api.exhibitionPage(data).then((res)=>{
                console.log(res.data.data , 'exhibitionList');
                if(that.pageNum == 1) {
                    that.pages = res.data.data.pages
                }
                that.list = res.data.data.list;
                console.log(that.list);
            })
        },
        goPageNum(obj) {
            // console.log(obj.page)
            let that = this;
            that.pageNum = obj.page;
            that.getList();
        },
        goDetail(item){
            let that = this;

            let staticData = {
                click: true,
                pv: false,
                search: false
            }
            that.addStatic(staticData);

            that.$router.push({
                name: 'exhibitionInfo',
                query: {
                    id: item.id
                }
            })
        },
        addStatic(data) {
            this.api.statisticsSave(data).then((res)=>{})
        }
    }
}
</script>
<style lang="scss" scoped>
    .container{
        width: 100%;
        padding-bottom: 120px;
        >.exhibition{
            width: 1200px;
            margin: 0 auto;
            >.bigHead{
                width: 259px;
                height: 49px;
                display: block;
                margin: 0 auto 46px;
            }
            >ul{
                >li{
                    cursor: pointer;
                    text-align: left;
                    display: flex;
                    align-items: center;
                    padding: 30px;
                    margin-bottom: 26px;
                    box-shadow: 0px 4px 15px 1px #BFD1D5;
                    border-radius: 6px;
                    border: 1px solid #BFD1D5;
                    >img{
                        display: block;
                        width: 280px;
                        height: 200px;
                        margin-right: 24px;
                    }
                    >div{
                        width: 0%;
                        flex: 1;
                        >h4{
                            font-size: 22px;
                            line-height: 30px;
                            color: #222;
                            margin-bottom: 16px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        >p:first-of-type{
                            font-size: 16px;
                            color: #4E4E4E;
                            line-height: 25px;
                            height: 100px;
                            margin-bottom: 30px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 4;
                            -webkit-box-orient: vertical;
                        }
                        >p:last-of-type{
                            font-size: 14px;
                            color: #909090;
                            line-height: 20px;
                        }
                    }
                    &:hover{
                        border: 1px solid #3DCCE7;
                    }
                }
            }
        }
        
    }
</style>
