<template>
    <div class="putQuestionsMethod">
        <img :src="imgLink" alt="">
    </div>
</template>

<script>

export default {
    components: {
        
    },
    data() {
        return {
            
        }
    },
    computed: {
        imgLink() {
            let that = this;
            let url = '';
            switch (that.$route.params.type) {
                case 'noticeToReaders':
                    url = 'https://resource.yuntuys.com/static/jiangan/1.png';
                    break;
                case 'notesForCertificateApplication':
                    url = require("@/assets/0.jpg");
                    break;
                case 'wifi':
                    url = require("@/assets/0.jpg");
                    break;
                case 'putQuestions':
                    url = require("@/assets/0.jpg");
                    break;
                case 'serviceData':
                    url = require("@/assets/0.jpg");
                    break;
                case 'donationThanks':
                    url = require("@/assets/0.jpg");
                    break;
                case 'branchLibrary':
                    url = require("@/assets/0.jpg");
                    break;
            
                default: url = require("@/assets/0.jpg");
                    break;
            }
            return url;
        }
    },
    mounted() {
        
    },
    methods: {
        
    }
};
</script>
<style lang="scss" scoped>
    .putQuestionsMethod{
        width: 100%;
        background: #fff;
        padding: 40px;
        >img{
            display: block;
            width: 100%;
        }
    }
</style>