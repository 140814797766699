<template>
<!-- http://192.168.2.14:8082/literatureOrAffairs?id=1&type=affairs -->
<!-- http://192.168.2.14:8082/literatureOrAffairs?type=affairs&id=7 -->
    <div class="container">
        <!-- 2123123123213213123123 -->
        <c-banner :type="type" :bannerHeight="'600px'" />
        <!-- 23423 -->
        <div class="main">
            <p class="navTitle" style="text-algin:left" v-if="this.$store.state.navTitle">
                <img src="../../assets/imgs/home/from.png" alt="">
                您当前的位置是 : {{navTitle}}&nbsp;>&nbsp;<span @click="back" style="cursor:pointer;">{{this.$store.state.navTitle}}&nbsp;></span>&nbsp;
                    <span>
                        {{title}}
                    </span>
            </p>
            <p class="navTitle" style="text-algin:left" v-else>
                <img src="../../assets/imgs/home/from.png" alt="">
                您当前的位置是 : {{navTitle}}&nbsp;>&nbsp;<span @click="back" style="cursor:pointer;">政务公开&nbsp;></span>&nbsp;
                    <span>
                        {{title}}
                    </span>
                <!-- <span @click="back" style="cursor:pointer;">您当前的位置是 : 网站首页</span>
                &nbsp;>&nbsp;
                    <span>
                        {{title}}
                    </span> -->
            </p>
            <div>
                <h2>{{detail.title}}</h2>
                <div>
                    <p>{{detail.time}}</p>
                    <p>来源：{{detail.source}}</p>
                </div>
                <div v-html="detail.content"></div>
                </div>
        </div>
    </div>
</template>

<script>
import cBanner from "@/components/c-banner";
export default {
    components: {
        cBanner
    },
    data() {
        return {
            detail: {},
            type: '',
            title : '' ,
            navTitle:'网站首页'
        }
    },
    computed: {
        // params() {
        //     return this.$route.params
        // },
        query() {
            return this.$route.query
        }
    },
    created() {

    },
    mounted() {
        let that = this;
        that.type = that.query.type;
        that.title = that.query.name;
        if(this.query.search == 'true'){
            this.navTitle = '网站搜索'
        }
        if(that.query.type == 'affairs') {
            that.getBulletinDetail();
        }else if(that.query.type == 'literature') {
            that.getLiteratureDetail();
        }
    },
    methods: {
        getBulletinDetail() {
            let that = this;
            let data = {
                id: that.query.id
            }
            that.api.bulletinDetail(data).then((res)=>{
                that.detail = res.data.data;
                console.log(res.data.data);
            })
        },
        getLiteratureDetail() {
            let that = this;
            let data = {
                id: that.query.id
            }
            that.api.literatureDetail(data).then((res)=>{
                that.detail = res.data.data;
            })
        },
        back(){
            this.$router.go(-1)
        }
    },
    watch:{
        query(newV) {
            console.log(newV);
            let that = this;
            if(that.query.type == 'affairs') {
                that.getBulletinDetail();
            }else if(that.query.type == 'literature') {
                that.getLiteratureDetail();
            }
        },
    }
};
</script>
<style lang="scss" scoped>
    .container{
        overflow: hidden;
        width: 100%;
        background: #F7F7F7;
        padding-bottom: 120px;
        >.main{
            width: 1200px;
            margin: 0 auto;
            padding: 40px;
            background: #fff;
            .navTitle{
                font-size: 14px;
                // font-family: MicrosoftYaHei;
                color: #666666;
                margin-bottom: 25px;
                display: flex;
                align-items: center;
                >img{
                    width: 16px;
                    height: 17px;
                    margin-right: 10px;
                }
                >span:last-child{
                    font-weight: 600;
                    color: #222222;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;text-overflow: ellipsis;
                }
            }
            >div{
                >h2{
                    color: #1F9EB6;
                    font-size: 24px;
                    line-height: 36px;
                }
                >div:first-of-type{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-bottom: 10px;
                    border-bottom: 1px solid #e1e1e1;
                    margin-bottom: 20px;
                    >p{
                        font-size: 14px;
                        color: #999;
                        line-height: 16px;
                        margin-right: 20px;
                    }
                }
            }
        }
    }
</style>