import Vue from "vue";
import VueRouter from "vue-router";

const originalPush = VueRouter.prototype.push
	VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

// 首页
import home from "../views/home.vue";
// 展厅列表
import exhibitionList from "../containers/exhibition/exhibitionList.vue";
// 展厅-详情
import exhibitionInfo from "../containers/exhibition/exhibitionInfo.vue";
// 新书推荐列表
import newBookList from "../containers/newBook/newBookList.vue";
// 书籍-详情
import newBookInfo from "../containers/newBook/newBookInfo.vue";
// 讲堂列表
import lectureList from "../containers/lecture/lectureList.vue";
// 讲堂-详情
import lectureInfo from "../containers/lecture/lectureInfo.vue";

// 关于岸图
import about from "../views/about.vue";

// 读者服务
import readerService from "../views/readerService.vue";

// 参考咨询
import consult from "../views/consult.vue";
// 参考咨询--咨询方式
import putQuestionsMethod from "../containers/consult/putQuestionsMethod.vue";
// 参考咨询--常见问题
import problemList from "../containers/consult/problemList.vue";
// 参考咨询--在线提问
import putQuestions from "../containers/consult/putQuestions.vue";

// 地方文献/政务公开
import literatureOrAffairs from "../views/literatureOrAffairs.vue";
// 地方文献/政务公开--详情
import literatureOrAffairsInfo from "../containers/literatureOrAffairs/literatureOrAffairsInfo.vue";

// Englist
import englishReaderService from "../views/englishReaderService.vue";

// 搜索
import search from "../views/search.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: home,
  },
  {
    path: "/exhibitionList",
    name: "exhibitionList",
    component: exhibitionList,
  },
  {
    path: "/exhibitionInfo",
    name: "exhibitionInfo",
    component: exhibitionInfo,
  },
  {
    path: "/newBookList",
    name: "newBookList",
    component: newBookList,
  },
  {
    path: "/newBookInfo",
    name: "newBookInfo",
    component: newBookInfo,
  },
  {
    path: "/lectureList",
    name: "lectureList",
    component: lectureList,
  },
  {
    path: "/lectureInfo",
    name: "lectureInfo",
    component: lectureInfo,
  },
  {
    path: "/about",
    name: "about",
    component: about,
  },
  {
    path: "/readerService",
    name: "readerService",
    component: readerService,
  },
  {
    path: "/englishReaderService/:type",
    name: "englishReaderService",
    component: englishReaderService,
  },
  {
    path: "/consult",
    name: "consult",
    component: consult,
    // redirect: {
    //   name: 'putQuestionsMethod'
    // },
    // children: [
    //   {
    //     path: "putQuestionsMethod",
    //     name: "putQuestionsMethod",
    //     component: putQuestionsMethod,
    //   },
      // {
      //   path: "problemList",
      //   name: "problemList",
      //   component: problemList,
      // },
    //   {
    //     path: "putQuestions",
    //     name: "putQuestions",
    //     component: putQuestions,
    //   }
    // ]
  },
  {
    path: "/literatureOrAffairs",
    name: "literatureOrAffairs",
    component: literatureOrAffairs,
  },
  {
    path: "/literatureOrAffairsInfo",
    name: "literatureOrAffairsInfo",
    component: literatureOrAffairsInfo,
  },
  {
    path: "/search",
    name: "search",
    component: search,
  },
];

const router = new VueRouter({
  // mode: "history",
  mode:'history',
  base: './',
  routes,
  // scrollBehavior(to, from, savedPosition) {
  //   return { x: 0, y: 0 }
  // }
});

//路由后scrollTop设置为0
function goScrollTop() {
  let bodySrcollTop = document.body.scrollTop
  if (bodySrcollTop !== 0) {
    document.body.scrollTop = 0
    return
  }
  let docSrcollTop = document.documentElement.scrollTop
  if (docSrcollTop !== 0) {
    document.documentElement.scrollTop = 0
  }
}


import allApi from "@/axios/index";
import store from "@/store/index";

function getStatics() {
  allApi.statisticsStatistics().then((res)=>{
    // console.log(res)
    store.commit("setPvs", res.data.data.pvs)
    store.commit("setPv", res.data.data.statistics.pv)
  })
}

function addPv() {
  let data = {
    click: false,
    pv: true,
    search: false
  }
  allApi.statisticsSave(data).then((res)=>{})
}

router.beforeEach((to, from, next) => {
  addPv()
	next();
});

router.afterEach((to, from) => {
	goScrollTop();
  getStatics();
});

export default router;
