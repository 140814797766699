<template>
    <div class="problemList">
        <div class="commonBox">
            <h3>留言板</h3>
            <div @click="goMessage">去留言</div>
        </div>
        <ul class="quertions">
            <li v-for="item of list" :key="item.id">
                 <span class="questioner" v-if="item.questioner">
                     {{item.questioner}}:
                 </span>
                <h4>{{item.title}}</h4>
                <p>{{item.content}}</p>
                <div>
                    <p>{{item.reply}}</p>
                </div>
            </li>
        </ul>
        <pager :total-page="pages" :initPage="pageNum" @go-page="goPageNum" v-if="pages>1" />
    </div>
</template>

<script>
import pager from '@/components/pager'
export default {
    components: {
        pager
    },
    data() {
        return {
            // commonList: [
            //     {
            //         id: -1,
            //         title: '全部'
            //     }
            // ],
            actTab: -1,
            list: [],
            pageNum: 1,
            pages: 1
        }
    },
    computed: {
        // params() {
        //     return this.$route.params
        // },
        query() {
            return this.$route.query
        }
    },
    created(){
        let that = this;
        that.getClassList();
        that.getList();
        // console.log(123);
    },
    mounted() {
        let that = this;
        // console.log(that.params);
        // console.log(that.$route);
    },
    methods: {
        goMessage(){
            this.$emit('myEvent','Hello World! I am Vue!');
                // this.isLeaveMessage = !this.isLeaveMessage
        } ,
        getClassList() {
            // let that = this;
            // that.api.questionTypes().then((res)=>{
            //     console.log(res.data);
            //     that.commonList = that.commonList.concat(res.data.data);
            // })
        },
        getList() {
            let that = this;
            let data = {
                page: that.pageNum,
                size: 4,
                // typeId: that.actTab == -1 ? '' : that.actTab
            }
            that.api.messageList(data).then((res)=>{
                if(that.pageNum == 1) {
                    that.pages = res.data.data.pages
                }
                that.list = res.data.data.list;
                // console.log(this.list);
            })
        },
        goPageNum(obj) {
            // console.log(obj.page)
            let that = this;
            that.pageNum = obj.page;
            that.getList();
        },
        selectClass(id) {
            let that = this;

            let staticData = {
                click: true,
                pv: false,
                search: true
            }
            that.addStatic(staticData);
            
            that.actTab = id;
            that.pageNum = 1;
            that.getList();
        },
        addStatic(data) {
            this.api.statisticsSave(data).then((res)=>{})
        }
    }
};
</script>
<style lang="scss" scoped>
    .problemList{
        overflow: hidden;
        width: 100%;
        background: #fff;
        // padding: 40px;
        text-align: left;
        >.commonBox{
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #E1E1E1;
            margin-bottom: 20px;
            >h3{
                font-size: 18px;
                line-height: 30px;
                color: #333;
                margin-right: 20px;
                margin-bottom: 20px;
            }
            >div{
                font-size: 14px;
                padding: 4px  12px;
                background: #0187ce;
                border-radius: 6px;
                color: #fff;
                cursor: pointer;
            }
        }
        >.quertions{
            overflow: hidden;
            >li{
                cursor: default;
                border-bottom: 1px solid #e1e1e1;
                margin-bottom: 20px;
                padding-bottom: 20px;
                >.questioner{
                    position: relative;
                    display: block;
                    font-size: 16px;
                    top: 15px;
                    font-weight: 600;
                    color: rgb(22, 21, 21);
                }
                >h4{
                    font-size: 16px;
                    color: #333;
                    line-height: 24px;
                    margin-bottom: 20px;
                }
                >p{
                    font-size: 16px;
                    color: #999;
                    line-height: 22px;
                    margin-bottom: 20px;
                }
                >div{
                    padding: 20px 30px;
                    background: #F4F8F9;
                    >p:first-child{
                        font-size: 16px;
                        color: #333;
                        line-height: 22px;
                    }
                }
            }
        }
    }
</style>