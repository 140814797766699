<template>
    <div class="container">
        <c-banner :type="'newBook'" :bannerHeight="'600px'" />
        <div class="newBook">
            <img src="@/assets/imgs/home/newBookHeadIco.png" alt="" class="bigHead">
            <ul>
                <li v-for="item of list" :key="item.id" @click="goDetail(item)">
                    <img :src="item.cover" alt="">
                    <div>
                        <h4>{{item.name}}</h4>
                        <p>作者：{{item.author}}</p>
                        <p>索书号：{{item.code}}</p>
                        <p>{{item.summary}}</p>
                    </div>
                </li>
            </ul>
            <pager :total-page="pages" :initPage="pageNum" @go-page="goPageNum" v-if="pages>1" />
        </div>
    </div>
</template>
<script>
import cBanner from "@/components/c-banner";
import pager from '@/components/pager'
export default {
    components: {
        pager,
        cBanner
    },
    data() {
        return {
            list: [],
            pages: 1,
            pageNum: 1
        }
    },
    created(){
        let that = this;
        that.getList();
    },
    methods: {
        getList() {
            let that = this;
            let data = {
                page: that.pageNum,
                size: 8
            }
            that.api.bookPage(data).then((res)=>{
                // console.log(res)
                if( that.pageNum == 1 ) {
                    that.pages = res.data.data.pages;
                }
                that.list = res.data.data.list;
            })
        },
        goPageNum(obj) {
            let that = this;
            that.pageNum = obj.page;
            that.getList();
        },
        goDetail(item){
            let that = this;

            let staticData = {
                click: true,
                pv: false,
                search: false
            }
            that.addStatic(staticData);

            that.$router.push({
                name: 'newBookInfo',
                query: {
                    id: item.id
                }
            })
        },
        addStatic(data) {
            this.api.statisticsSave(data).then((res)=>{})
        }
    }
}
</script>
<style lang="scss" scoped>
    .container{
        width: 100%;
        padding-bottom: 120px;
        >.newBook{
            width: 1200px;
            margin: 0 auto;
            >.bigHead{
                width: 259px;
                height: 49px;
                display: block;
                margin: 0 auto 46px;
            }
            >ul{
                >li{
                    cursor: pointer;
                    float: left;
                    width: 588px;
                    text-align: left;
                    display: flex;
                    align-items: center;
                    padding: 20px;
                    margin-bottom: 26px;
                    box-shadow: 0px 4px 15px 1px #BFD1D5;
                    border-radius: 6px;
                    border: 1px solid #BFD1D5;
                    >img{
                        display: block;
                        width: 150px;
                        height: 215px;
                        margin-right: 20px;
                    }
                    >div{
                        width: 0%;
                        flex: 1;
                        >h4{
                            font-size: 22px;
                            line-height: 30px;
                            color: #222;
                            margin-bottom: 16px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        >p:first-of-type{
                            font-size: 14px;
                            color: #666;
                            line-height: 20px;
                            
                        }
                        >p:nth-of-type(2){
                            font-size: 14px;
                            color: #666;
                            line-height: 20px;
                            margin-bottom: 10px;
                        }
                        >p:last-of-type{
                            font-size: 14px;
                            color: #666;
                            line-height: 20px;
                            height: 91px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 4;
                            -webkit-box-orient: vertical;
                            border-top: 1px dashed #e1e1e1;
                            padding-top: 10px;
                        }
                    }
                    &:nth-child(2n){
                        float: right;
                    }
                    &:hover{
                        border: 1px solid #3DCCE7;
                    }
                }
                &::after{
                    content: "";
                    width: 0;
                    height: 0;
                    display: block;
                    clear: both; 
                    visibility: hidden; 
                }
            }
        }
    }
</style>